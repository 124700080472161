import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import FileUploader from 'components/molecules/FileUploader';
import { niceBytes } from 'helpers/bytesCalculator';
import { getFileNameWithExtension } from 'helpers/filenames';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { ChronosDoc } from 'types';

import { MB_150_IN_BYTES, GB_2_IN_BYTES } from './constants';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

class UploadError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

interface ExtendedChronosDoc extends ChronosDoc {
  isDuplicate?: boolean;
  isUnsupported?: boolean;
}

const FileUploaderComponent = ({
  setUploadDisabled,
  setTotalActiveDocs,
}: {
  setUploadDisabled: (disabled: boolean) => void;
  setTotalActiveDocs: (docNumber: number) => void;
}) => {
  // State
  const [successfulUploads, setSuccessfulUploads] = useState<number>(0);
  const [expectedUploads, setExpectedUploads] = useState<number>(0);
  const [docsTab, setDocsTab] = useState<string>('active');
  const [posting, setPosting] = useState(false);
  const [docRemoving, setDocRemoving] = useState<string>('');

  // Hooks
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  // Data fetching
  const {
    data: responseDocs,
    refetch: refetchCaseDocs,
    isLoading: isLoadingDocuments,
  } = useQuery(
    ['userNewDocs'],
    () => fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => res.json()),
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  // Effects
  useEffect(() => {
    // Fetch docs on first load
    refetchCaseDocs();
  }, [refetchCaseDocs]);

  // Handlers
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setPosting(true);
      setSuccessfulUploads(0);
      setExpectedUploads(acceptedFiles.length);

      const uploadPromises = acceptedFiles.map(async (file) => {
        try {
          await uploadFile(file);
          setSuccessfulUploads((prev) => prev + 1);
        } catch (error) {
          if (error instanceof UploadError) {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });

      await Promise.allSettled(uploadPromises);

      refetchCaseDocs();
      setPosting(false);
    },
    // eslint-disable-next-line
    [caseId],
  );

  // Doc CRUD ------------------------------------------
  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      if (!caseId) return;

      if (['.zip', '.rar'].includes(file.name.slice(-4))) {
        Swal.fire({
          title: 'Unsupported File Type',
          text: `The file ${file.name} is not supported. Please upload supported file types.`,
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('caseId', caseId);

        const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });

        const response = await fetch(`${APIBaseChronos}/client/case/doc`, fetchConfig);
        if (!response.ok) {
          const errorData = await response.json();
          throw new UploadError(errorData.message || 'Upload failed', response);
        }
        setSuccessfulUploads((prev) => prev + 1);
      } catch (error) {
        if (error instanceof UploadError) {
          Swal.fire({
            title: 'Error',
            text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
    [caseId, getFetchConfig],
  );

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    await refetchCaseDocs();
    setDocRemoving('');
  };
  // --------------------------------------------------------

  // Doc Filtering
  const docsInCase = useMemo(() => {
    return responseDocs?.docs.filter((doc: any) => doc.run_id != null) || [];
  }, [responseDocs?.docs]);

  const docsToRun = useMemo(() => {
    return responseDocs?.docs.filter((doc: any) => !doc.run_id) || [];
  }, [responseDocs?.docs]);

  // Splitting docs into active and removed
  const activeDocsAlreadyInCase = useMemo(() => docsInCase.filter((doc: ChronosDoc) => !doc.is_removed), [docsInCase]);
  const activeDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => !doc.is_removed), [docsToRun]);
  const removedDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => doc.is_removed), [docsToRun]);

  const markDuplicatesAndUnsupported = (
    docsArray: ChronosDoc[],
    groupedByHash: Record<string, ChronosDoc[]>,
  ): ExtendedChronosDoc[] => {
    return docsArray.map((doc) => ({
      ...doc,
      isDuplicate: groupedByHash[doc.file_hash]?.length > 1,
      alreadyExistsInCase: activeDocsAlreadyInCase.filter((docInCase: any) => docInCase.file_hash === doc.file_hash),
      isUnsupported: doc.file_extension?.toLowerCase() === 'zip' || doc.file_extension?.toLowerCase() === 'rar',
    }));
  };

  const groupDocsByHash = (docsArray: ChronosDoc[]): Record<string, ChronosDoc[]> => {
    return docsArray.reduce<Record<string, ChronosDoc[]>>((acc, doc) => {
      const key = doc.file_hash || 'undefined'; // Handle undefined file_hash appropriately
      (acc[key] = acc[key] || []).push(doc);
      return acc;
    }, {});
  };

  const sortDocs = (docsArray: ChronosDoc[]): ChronosDoc[] => {
    const groupedByHash = groupDocsByHash(docsArray);
    const markedDocs = markDuplicatesAndUnsupported(docsArray, groupedByHash);

    return markedDocs.sort((a, b) => {
      if (a.isUnsupported && !b.isUnsupported) return -1;
      if (!a.isUnsupported && b.isUnsupported) return 1;

      const aExistsInCase = a.alreadyExistsInCase.length > 0;
      const bExistsInCase = b.alreadyExistsInCase.length > 0;
      if (aExistsInCase && !bExistsInCase) return -1;
      if (!aExistsInCase && bExistsInCase) return 1;

      // Prioritize duplicates within the group that doesn't already exist in the case
      const aIsDuplicate = a.isDuplicate;
      const bIsDuplicate = b.isDuplicate;
      if (aIsDuplicate && !bIsDuplicate) return -1;
      if (!aIsDuplicate && bIsDuplicate) return 1;

      // Sort by file hash as a final tiebreaker
      if (!a.file_hash && b.file_hash) return 1;
      if (a.file_hash && !b.file_hash) return -1;
      return a.file_hash ? a.file_hash.localeCompare(b.file_hash || '') : 0;
    });
  };
  // --------------------------------------------------------

  const showActive = () => {
    setDocsTab('active');
  };

  const showRemoved = () => {
    setDocsTab('removed');
  };

  // Sorted and grouped active documents
  // eslint-disable-next-line
  const sortedActiveDocs = useMemo(() => sortDocs(activeDocs), [activeDocs]);
  const groupedActiveDocs = useMemo(() => groupDocsByHash(sortedActiveDocs), [sortedActiveDocs]);

  // Sorted and grouped removed documents
  // eslint-disable-next-line
  const sortedRemovedDocs = useMemo(() => sortDocs(removedDocs), [removedDocs]);
  const groupedRemovedDocs = useMemo(() => groupDocsByHash(sortedRemovedDocs), [sortedRemovedDocs]);

  const duplicatesActive = Object.values(groupedActiveDocs).filter((group: any) => group.length > 1);
  const duplicatesRemoved = Object.values(groupedRemovedDocs).filter((group: any) => group.length > 1);

  const sum = activeDocs?.docs?.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;
  const prohibited_docs_length = activeDocs?.docs?.filter((doc: any) => {
    return doc?.file_size > MB_150_IN_BYTES;
  }).length;
  const only_1_prohibited = prohibited_docs_length === 1;

  const size_limit_exceeded = sum > GB_2_IN_BYTES;
  const count_limit_exceeded = (activeDocs?.docs?.length || 0) > 1000;

  const unsupported_docs = sortedActiveDocs.filter((doc: ExtendedChronosDoc) => doc.isUnsupported);
  const unsupported_docs_length = unsupported_docs.length;

  const message = prohibited_docs_length
    ? `${prohibited_docs_length} file${only_1_prohibited ? '' : 's'} exceed${
        only_1_prohibited ? 's' : ''
      } 150MB file size limit`
    : size_limit_exceeded && count_limit_exceeded
    ? 'Exceeded 2GB file size and 1000 file count limits.'
    : size_limit_exceeded
    ? 'Exceeded 2GB file size limit.'
    : count_limit_exceeded
    ? 'Exceeded 1000 file count limit.'
    : unsupported_docs_length
    ? `${unsupported_docs_length} unsupported file${unsupported_docs_length === 1 ? '' : 's'} (zip/rar).`
    : '';

  useEffect(() => {
    setUploadDisabled(
      !!duplicatesActive.length ||
        prohibited_docs_length > 0 ||
        posting ||
        !responseDocs?.docs?.length ||
        size_limit_exceeded ||
        count_limit_exceeded ||
        unsupported_docs.length > 0 ||
        activeDocs.length === 0,
    );
    setTotalActiveDocs(activeDocs.length);
  }, [
    duplicatesActive,
    prohibited_docs_length,
    responseDocs,
    posting,
    size_limit_exceeded,
    count_limit_exceeded,
    unsupported_docs,
    activeDocs,
    setTotalActiveDocs,
    setUploadDisabled,
  ]);

  return (
    <div>
      {!!message && <div className="text-red-700 font-semibold italic">{message}</div>}
      <FileUploader
        uploading={posting}
        onDrop={onDrop}
        expectedUploads={expectedUploads}
        successfulUploads={successfulUploads}
      />
      <div className="w-full flex flex-column">
        {isLoadingDocuments && (
          <div className="w-full h-32 flex justify-center items-center">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        )}
        {responseDocs?.docs && responseDocs?.docs.length > 0 && (
          <div className="w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row mb-2">
                <div
                  className={`px-6 py-1 ${
                    docsTab === 'active' ? 'border-b-2  border-brandSecondary' : 'text-gray-600'
                  } cursor-pointer`}
                  onClick={showActive}
                >
                  Active
                </div>
                <div
                  className={`px-6 py-1 ${
                    docsTab === 'removed' ? 'border-b-2  border-brandSecondary' : 'text-gray-600'
                  } cursor-pointer`}
                  onClick={showRemoved}
                >
                  Removed
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 justify-between overflow-y-scroll h-28 border rounded p-2 bg-gray-50">
              {(docsTab === 'active' ? sortedActiveDocs : sortedRemovedDocs)?.map((doc: any, idx: number) => {
                const file_size_exceeded = doc.file_size > MB_150_IN_BYTES;
                const isDuplicate = (docsTab === 'active' ? duplicatesActive : duplicatesRemoved).some((group: any) => {
                  if (!doc.file_hash) return false;
                  return group.some((dupDoc: any) => dupDoc.file_hash === doc.file_hash);
                });
                const isUnsupported = doc.isUnsupported;
                return (
                  <div
                    className={`bg-white p-2 w-72 rounded h-8 flex items-center text-xs border ${
                      isUnsupported || file_size_exceeded
                        ? 'border-red-500 text-red-700'
                        : isDuplicate
                        ? 'border-orange-500 text-orange-700'
                        : 'text-blue-700 border-blue-500'
                    }`}
                    key={doc._id}
                  >
                    <div className="w-full flex items-center justify-between">
                      {/* {File name} */}
                      <div className="w-1/3 shrink-0 truncate">{getFileNameWithExtension(doc?.file_name)}</div>
                      {/* Extension */}
                      <div className="w-1/3 shrink-0 flex items-center justify-center">
                        <div
                          className={`rounded flex justify-center items-center p-1 uppercase ${
                            file_size_exceeded || isUnsupported ? 'bg-red-200' : ''
                          }  ${isDuplicate ? 'bg-orange-200' : 'bg-blue-50'}`}
                        >
                          {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                        </div>
                      </div>
                      {/* Size */}
                      <div className="flex flex-row items-center justify-center">
                        {file_size_exceeded && !isDuplicate && (
                          <>
                            <FontAwesomeIcon
                              data-tooltip-id={`tooltip-exceeded-file-size-${idx}`}
                              icon={faInfoCircle}
                              data-tooltip-content="File size too large for processing"
                              className="text-red-700 ml-2 text-sm cursor-pointer mr-3"
                              style={{ width: '16px' }}
                            />
                            <Tooltip
                              id={`tooltip-exceeded-file-size-${idx}`}
                              className="z-10"
                              style={{
                                display: 'flex',
                                width: '300px',
                                padding: '16px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                borderRadius: '16px',
                                background: '#FFF',
                                boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                                color: 'var(--colors-primary-slate-600, #455468)',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                                letterSpacing: '-0.3px',
                              }}
                            />
                          </>
                        )}
                        {isDuplicate && 'Duplicate'}
                        {isUnsupported && 'Unsupported'}
                        {!isDuplicate && !isUnsupported && (doc.file_size ? `${niceBytes(doc.file_size)}` : '---')}
                      </div>

                      {docsTab === 'active' ? (
                        <div
                          className={`flex justify-center items-center px-2 py-1 rounded-lg ml-auto w-8 not-italic shrink-0 font-bold cursor-pointer`}
                          onClick={() => handleRemoveDoc(doc.doc_id)}
                        >
                          {docRemoving === doc.doc_id ? (
                            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
                          ) : (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                        </div>
                      ) : (
                        <div className="w-24"></div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FileUploaderComponent;
