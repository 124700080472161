import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { CHRONOLOGY_STEPS } from 'constants/tours';
import { useSearchParams } from 'react-router-dom';
import Tour from 'reactour';
import Swal from 'sweetalert2';
import { ChronosDoc, ChronosFact } from 'types';
import { Filters } from 'types';

import FactsEditorTable from './FactsEditorTable';
import FactsEditorToolbar from './FactsEditorToolbar';
import useFactsData from './hooks/useFactsData';
import useKeyFacts from './hooks/useKeyFacts';
import useToggleAll from './hooks/useToggleAllIncluded';
import useFetchDocs from '../DocumentsEditor/hooks/useFetchDocs';

interface FactsEditorProps {
  docId?: string;
  onlyTable?: boolean;
}

const FactsEditor = ({ docId }: FactsEditorProps) => {
  const [globalFilterInput, setGlobalFilterInput] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');

  // Query params
  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';

  const [keyFactFilterActive, setKeyFactFilterActive] = useState(false);
  const [filters, setFilters] = useState<Filters>({});
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [allEventIds, setAllEventIds] = useState<string[]>([]);
  const [isDateDirty, setIsDateDirty] = useState(false);
  const [isLoadingFacts, setIsLoadingFacts] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>(''); // State to hold current user ID
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [totalNumberOfFacts, setTotalNumberOfFacts] = useState(0);
  const [totalNumberOfIncludedFacts, setTotalNumberOfIncludedFacts] = useState(0);
  const [createChronologyTourOpen, setCreateChronologyTourOpen] = useState(false);

  // Custom hooks
  const { fetchConfigGET } = useGetFetchConfig();
  const putIncludedToggle = useToggleAll();

  // Data - facts, key facts
  const {
    data: responseFacts,
    isLoading,
    isFetching,
    refetch: refetchCaseFacts,
  } = useFactsData(docId, caseId, globalFilter, pagination.pageIndex + 1, pagination.pageSize, filters);
  const { data: keyFactsData } = useKeyFacts(caseId);

  const [keyFactsSet, setKeyFactsSet] = useState(new Set<string>());

  const { data: responseDocs, refetch: refetchCaseDocs } = useFetchDocs(caseId);

  const clearSearch = async () => {
    setGlobalFilterInput('');
    setGlobalFilter('');
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetchCaseFacts();
  };

  const handleGlobalFilterChange = (value: string) => {
    setGlobalFilterInput(value);
  };
  // ---------------------------------------------------------------------------

  // useEffects
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await fetch(`${APIBaseChronos}/client/user/user`, fetchConfigGET);
        if (!res.ok) throw new Error('Network response was not ok');
        const data = await res.json();
        setCurrentUserId(data.user.userId);
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };

    fetchCurrentUser();
  }, [fetchConfigGET]);

  useEffect(() => {
    setKeyFactsSet(new Set(keyFactsData?.event_ids));

    // Check the search params for the key fact true option and if so, then set key fact filters
    if (searchParams.get('viewKeyFacts') === 'true' && keyFactsData) {
      setKeyFactFilterActive(true);
      setFilters({ event_id: keyFactsData.event_ids.map((id) => ({ label: id, value: id })) });
    }
  }, [keyFactsData, searchParams]);

  useEffect(() => {
    setIsLoadingFacts(isLoading);
  }, [isLoading]);

  useEffect(() => {
    refetchCaseFacts();
    // eslint-disable-next-line
  }, [pagination.pageIndex, filters]);

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (globalFilter === '') {
      setAllEventIds([]);
    }
  }, [globalFilter]);

  // Post process facts after they are fetched
  useEffect(() => {
    if (responseFacts?.facts) {
      setTotalNumberOfFacts(responseFacts.totalFactsCount);
      setTotalNumberOfIncludedFacts(responseFacts.includedFactsCount);

      const modifiedFacts = responseFacts.facts.map((fact: ChronosFact) => ({
        ...fact,
        source_doc: fact.documents.map((doc) => doc.doc_id).join(','),
      }));
      setFacts(modifiedFacts);

      // If result has come from search then we have a file called eventIds with all the vent ids from the search
      if (responseFacts.eventIds) {
        setAllEventIds(responseFacts.eventIds);
      }
    }
    setIsDateDirty(false);
    setIsLoadingFacts(false);
  }, [responseFacts]);

  // Start chronology tour from summary page
  useEffect(() => {
    // Check if explore = true is in seach params
    if (isLoadingFacts || isLoading || isFetching) return;
    if (searchParams.get('chronologyTour') === 'true') {
      setCreateChronologyTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('chronologyTour');
      // Update the URL without the 'chronologyTour' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, isLoadingFacts, isLoading, isFetching]);

  // Handlers
  const handleToggleAll = (toggleValue: boolean): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      if (!caseId) {
        reject(new Error('No caseId provided'));
        return;
      }

      let filterParams = Object.keys(filters)
        .map((key) => {
          const values = filters[key];
          if (Array.isArray(values)) {
            return `filter_${key}=${values.map((val) => val.value).join(',')}`;
          }
          return `filter_${key}=${values}`;
        })
        .join('&');

      // Also if allEventIds is set, then add them to the filterParams
      if (allEventIds.length > 0) {
        filterParams += `&filter_event_id=${allEventIds.join(',')}`;
      }

      try {
        await putIncludedToggle.mutateAsync({ caseId, toggleValue, filterParams: filterParams });
        await refetchCaseFacts();
        resolve();
      } catch (err) {
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error updating the fields. Please try again later.',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
        });
        reject(err);
      }
    });
  };

  const onSearchCall = async () => {
    if (!docId) {
      // Reset page to 0
      const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
      setPagination(newPaginationState);

      setGlobalFilter(globalFilterInput); // Set the actual filter used for fetching
      await new Promise((resolve) => setTimeout(resolve, 0));
      refetchCaseFacts(); // Trigger the refetch
    }
  };

  const handleKeyFactFilterToggle = async () => {
    // If keyFactFilterActive is currently true, then remove the event_id filter from the filters
    if (keyFactFilterActive) {
      setKeyFactFilterActive(!keyFactFilterActive);
      const newFilters = { ...filters };
      delete newFilters['event_id'];
      setFilters(newFilters);
      // Remove
    } else {
      // If keyFactFilterActive is currently false, then fetch key fact event_ids and add to filters
      setKeyFactFilterActive(!keyFactFilterActive);
      if (keyFactsData?.event_ids) {
        goToPage(0);
        const keyFactFilters = keyFactsData.event_ids.map((id) => ({ label: id, value: id }));
        const newFilters = { ...filters };
        newFilters['event_id'] = keyFactFilters;
        setFilters(newFilters);
      }
    }
  };

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);
  const totalPages = Math.ceil((totalNumberOfFacts || 0) / pagination.pageSize);

  return (
    <div className="flex flex-col h-full">
      <FactsEditorToolbar
        docId={docId}
        caseId={caseId}
        isLoading={isLoading || isFetching}
        // Search
        globalFilter={globalFilterInput}
        clearSearch={clearSearch}
        setGlobalFilter={handleGlobalFilterChange}
        onSearchCall={onSearchCall}
        // Results
        refetchCaseFacts={refetchCaseFacts}
        resultsLength={totalNumberOfFacts}
        resultsIncludedLength={totalNumberOfIncludedFacts}
        // Pagination
        canGetPrevPage={pagination.pageIndex > 0}
        canGetNextPage={pagination.pageIndex < totalPages - 1}
        prevPage={prevPage}
        nextPage={nextPage}
        goToPage={goToPage}
        currentPage={pagination.pageIndex}
        noOfPages={totalPages}
        isDateDirty={isDateDirty}
        // Key facts
        keyFactFilterActive={keyFactFilterActive}
        handleKeyFactFilterToggle={handleKeyFactFilterToggle}
        // -----------------
        handleToggleAll={handleToggleAll}
        allDocuments={(responseDocs?.docs as ChronosDoc[]) || []}
        activeFilters={filters}
        setFilters={setFilters}
      />

      <div className="flex-grow overflow-hidden">
        {isLoadingFacts || isFetching ? (
          <div className="h-full w-full flex items-center justify-center">
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          </div>
        ) : (
          <div className="h-full overflow-y-auto">
            <FactsEditorTable
              facts={facts}
              keyFactIdSet={keyFactsSet}
              caseId={caseId || ''}
              userId={currentUserId}
              setIsDateDirty={setIsDateDirty}
              setTotalNumberOfIncludedFacts={setTotalNumberOfIncludedFacts}
            />
          </div>
        )}
      </div>
      <Tour
        startAt={0}
        steps={CHRONOLOGY_STEPS}
        isOpen={createChronologyTourOpen}
        onRequestClose={() => setCreateChronologyTourOpen(false)}
      />
    </div>
  );
};

export default FactsEditor;
