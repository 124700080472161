import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { ChronosFact, Filters } from 'types';

interface ChronosFactResponse {
  facts: ChronosFact[];
  totalFacts: number;
  appliedFilters: [string, string][];
  appliedSortings: [string, string][];
  page: number;
  pageSize: number;
  totalFactsCount: number;
  includedFactsCount: number;
  eventIds: string[];
}

function useFactsData(
  docId: string | null | undefined,
  caseId: string | null | undefined,
  searchQuery: string | null | undefined,
  page: number,
  pageSize: number,
  filters: Filters,
) {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<ChronosFactResponse, Error>(
    ['userFacts', docId, caseId, searchQuery, page, pageSize, filters],
    async () => {
      const encodedSearchQuery = searchQuery ? encodeURIComponent(searchQuery) : '';

      const filterParams = Object.keys(filters)
        .map((key) => {
          const values = filters[key];
          if (Array.isArray(values)) {
            return `filter_${key}=${values.map((val) => val.value).join(',')}`;
          }
          return `filter_${key}=${values}`;
        })
        .join('&');

      const response = await fetch(
        `${APIBaseChronos}/client/case/fact/${caseId}?docId=${docId}&searchQuery=${encodedSearchQuery}&page=${page}&pageSize=${pageSize}&sort_date_of_subject=asc&${filterParams}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: false },
  );

  return { data, error, isLoading, isFetching, refetch };
}

export default useFactsData;
