import { useCallback, useEffect, useState } from 'react';

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faStar, faPlusCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import ExpandableButton from 'components/molecules/ExpandableButton';
import CustomModal from 'components/molecules/Modals/Settings';
import { SEARCH_STEPS } from 'constants/tours';
import Pagination from 'PrivateApp/Chronos/Pagination';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Tour from 'reactour';
import Swal from 'sweetalert2';
import { ChronosChronology, ChronosDoc } from 'types';
import { Filters } from 'types';

import FactsFilterPopup from './components/FilterPopup';
import DataViewToolbar from '../../components/DataViewToolbar';
import SearchBox from '../../components/SearchBox';
import ChronologyDropdown from '../components/ChronologyDropdown';
import IncludeAllDropdown from '../components/IncludeAllDropdown';
import FactAdder from '../FactAdder';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

interface FactsEditorToolbarProps {
  caseId: string | null;
  docId?: string;
  globalFilter: string;
  resultsLength: number;
  resultsIncludedLength: number;
  isDateDirty: boolean;
  refetchCaseFacts: () => void;
  clearSearch: () => void;
  setGlobalFilter: (value: string) => void;
  canGetPrevPage: boolean;
  canGetNextPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  currentPage: number;
  noOfPages: number;
  onSearchCall: () => void;
  isLoading: boolean;
  goToPage: (value: number) => void;
  keyFactFilterActive: boolean;
  handleKeyFactFilterToggle: () => void;
  handleToggleAll: (v: boolean) => void;
  allDocuments: ChronosDoc[];
  activeFilters: Filters;
  setFilters: (v: any) => void;
}

const FactsEditorToolbar = ({
  docId,
  caseId,
  globalFilter,
  refetchCaseFacts,
  clearSearch,
  setGlobalFilter,
  resultsLength,
  resultsIncludedLength,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isDateDirty,
  onSearchCall,
  isLoading,
  goToPage,
  keyFactFilterActive,
  handleKeyFactFilterToggle,
  handleToggleAll,
  allDocuments,
  activeFilters,
  setFilters,
}: FactsEditorToolbarProps) => {
  // State
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [selectChronologyModalIsOpen, setSelectChronologyModalIsOpen] = useState(false);
  const [isLoadingChronology, setIsLoadingChronology] = useState(false);
  const [chronologies, setChronologies] = useState<ChronosChronology[]>([]);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [exploreTourOpen, setExploreTourOpen] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // Data fetching
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const fetchCaseDocs = () => {
    return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => res.json());
  };

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(['userDocs', docId], fetchCaseDocs, {
    enabled: false,
  });

  // Effects
  useEffect(() => {
    if (responseDocs?.docs && responseDocs.docs.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  useEffect(() => {
    refetchCaseDocs();
  }, [docId, refetchCaseDocs]);

  const handleNewFactCreated = useCallback(() => {
    refetchCaseFacts();
    closeModal();
    // eslint-disable-next-line
  }, [refetchCaseFacts]);

  useEffect(() => {
    // Check if explore = true is in seach params
    if (searchParams.get('explore') === 'true') {
      setExploreTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('explore');
      // Update the URL without the 'explore' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let count = 0;
    Object.keys(activeFilters).forEach((key) => {
      if (key !== 'event_id' && activeFilters[key].length > 0) {
        count += 1;
      }
    });
    setActiveFilterCount(count);
  }, [activeFilters]);

  // Handlers
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const { isFetching: isLoadingChronologies, refetch: refetchChronologies } = useQuery(
    ['fetchChronologies', caseId],
    fetchChronologies,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  async function fetchChronologies() {
    const response = await fetch(
      `${APIBaseChronos}/client/case/chronology/byCaseId/${caseId}?search=${''}`,
      fetchConfigGET,
    );
    const data = await response.json();
    setChronologies(data.chronologies);
  }

  async function startChronology(chronologyId: string | null) {
    setIsLoadingChronology(true);
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        caseId,
        add_to_existing: !!chronologyId,
        existing_chronology_id: chronologyId,
      },
    });

    try {
      const res = await fetch(`${APIBaseChronos}/client/case/chronology`, fetchConfig);
      const responseJson = await res.json();
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('chronologyId', responseJson.lambdaResponse.chronology_id);
      searchParams.set('title', responseJson.lambdaResponse.chronology_title);
      searchParams.set('page', '1');
      navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString(), { replace: true });
    } catch (err) {
      console.error('Fetch Error: ', err);
      Swal.fire({
        title: 'Error while creating the chronology',
        text: 'Please try again later',
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsLoadingChronology(false);
    }
  }

  const handleCreateChronology = () => {
    startChronology(null);
  };

  const handleAddToExistingChronology = () => {
    setSelectChronologyModalIsOpen(true);
    refetchChronologies();
  };

  const handleSelectChronology = (chronologyId: string) => {
    closeSelectChronologyModal();
    startChronology(chronologyId);
  };

  const closeSelectChronologyModal = useCallback(() => {
    setSelectChronologyModalIsOpen(false);
  }, []);

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Add Fact">
        <FactAdder docs={docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
      </Modal>

      <Modal
        style={customStyles}
        isOpen={selectChronologyModalIsOpen}
        onRequestClose={closeSelectChronologyModal}
        contentLabel="Select Chronology"
      >
        <div className="p-4 overflow-auto flex items-center justify-start flex-col h-96 overflow-y-scroll">
          <h2 className="text-xl font-bold mb-4">Select Existing Chronology</h2>
          {isLoadingChronologies ? (
            <StageSpinner className="m-auto" color={'#4161FF'} size={25} />
          ) : (
            <div className="w-full">
              {chronologies.map((chronology) => (
                <button
                  key={chronology.chronology_id}
                  className="flex justify-center items-center px-2 py-3 rounded-lg not-italic w-full font-bold cursor-pointer ml-auto mt-2"
                  style={{
                    backgroundColor: '#ECEFFF',
                    color: '#4161FF',
                  }}
                  onClick={() => handleSelectChronology(chronology.chronology_id)}
                >
                  {chronology.latest_title}
                </button>
              ))}
            </div>
          )}
        </div>
      </Modal>

      <DataViewToolbar
        titleSection={
          <>
            <div className="flex items-center">
              <h1 className="text-lg font-semibold">Facts </h1>
              <span className="text-xs font-normal border px-2 bg-gray-50 border-brandSecondary rounded ml-2 border-opacity-60">
                <b>{resultsLength}</b> shown
              </span>
            </div>
            <div className="flex items-center">
              <IncludeAllDropdown
                allRowsChecked={resultsLength === resultsIncludedLength}
                anyRowsChecked={resultsIncludedLength > 0}
                handleSelectAll={() => handleToggleAll(true)}
                handleClearAll={() => handleToggleAll(false)}
              />
              <span className="text-xs font-normal border px-2 bg-gray-50 border-brandSecondary rounded ml-4 border-opacity-60">
                <b>{resultsIncludedLength}</b> selected
              </span>
            </div>
          </>
        }
        actionButtons={
          <>
            <ChronologyDropdown
              createChronologyDisabled={resultsIncludedLength === 0}
              isLoadingChronology={isLoadingChronology}
              handleCreateChronology={handleCreateChronology}
              handleAddToExistingChronology={handleAddToExistingChronology}
            />
            <Button
              icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
              text="Add Fact"
              onClick={() => setModalIsOpen(true)}
              type="primary"
              size="small"
              className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
            />
          </>
        }
        searchBar={
          <div id="facts-search-bar">
            <SearchBox
              value={globalFilter}
              placeholder={`Search ${resultsLength} facts`}
              onChange={setGlobalFilter}
              onSearchCall={onSearchCall}
              clearSearch={clearSearch}
            />
          </div>
        }
        filterButtons={
          <>
            {isDateDirty && (
              <div className="flex justify-center items-center transition-transform transform scale-0 animate-popIn">
                <ExpandableButton
                  icon={faArrowsRotate}
                  onClick={refetchCaseFacts}
                  className="py-1 text-xs hover:bg-gray-100 flex items-center rounded border text-buttonPrimary transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center"
                >
                  <p className="text-sm">Refresh Table</p>
                </ExpandableButton>
              </div>
            )}
            <ExpandableButton
              isActive={keyFactFilterActive}
              icon={keyFactFilterActive ? faStar : farStar}
              onClick={handleKeyFactFilterToggle}
              className={`${
                keyFactFilterActive ? 'text-yellow-500' : 'text-buttonPrimary'
              } py-1 h-7 text-xs shadow-sm hover:bg-gray-100 flex items-center rounded border transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center`}
            >
              <p className="text-sm">Key Facts</p>
            </ExpandableButton>
            <div className="relative">
              <ExpandableButton
                icon={faFilter}
                onClick={() => setFiltersModalOpen(true)}
                className="text-buttonPrimary shadow-sm py-1 h-7 text-xs hover:bg-gray-100 flex items-center rounded border transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center"
              >
                <p className="text-sm">Filters</p>
              </ExpandableButton>
              {activeFilterCount > 0 ? (
                <div className="text-xs absolute top-[-8px] right-[-8px] rounded-full px-[4px] bg-brandTertiary border bg-opacity-40">
                  {activeFilterCount}
                </div>
              ) : null}
            </div>
          </>
        }
        pagination={
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            noOfPages={noOfPages}
            goToPage={goToPage}
          />
        }
      />

      <Tour
        startAt={0}
        steps={SEARCH_STEPS}
        isOpen={exploreTourOpen}
        onRequestClose={() => setExploreTourOpen(false)}
      />
      <CustomModal
        title={
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
            Filters
          </div>
        }
        content={
          <FactsFilterPopup
            activeFilters={activeFilters}
            allDocuments={allDocuments}
            setFilters={setFilters}
            handleClose={() => setFiltersModalOpen(false)}
          />
        }
        isOpen={filtersModalOpen}
        size="small"
        handleClose={() => setFiltersModalOpen(false)}
      />
    </>
  );
};

export default FactsEditorToolbar;
