import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Answer from './Answer';
import useCreateThreadAndMessage from './hooks/useCreateThreadAndMessage';
import useGetThreads from './hooks/useGetThreads';
import QuestionInput from './QuestionInput';
import ThreadsBar from './ThreadsBar';

interface Message {
  type: string;
  msg: string;
  trafficLight: string;
  answer: string;
  facts: any[];
  chunks: any[];
  threadId: string;
  question: string;
}

const CaseAssistant = React.memo(() => {
  // State
  const [questionValue, setQuestionValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Message>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const caseId = searchParams.get('caseId');
  const threadId = searchParams.get('threadId');

  // Data fetching
  const { responseThreads, isLoadingThreads, refetchThreads } = useGetThreads(caseId || '');
  // const { isLoadingMessages, responseMessages, refetchMessages } = useGetMessages(threadId || '');
  // Mutations
  const { mutate: createThreadAndMessage } = useCreateThreadAndMessage();

  // Effects
  useEffect(() => {
    refetchThreads();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (threadId) {
  //     refetchMessages();
  //   }
  //   // eslint-disable-next-line
  // }, [threadId]);

  // Handlers
  const handleNewThread = useCallback(() => {
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [navigate, location, searchParams]);

  const goToThread = useCallback(
    (thread_id: string) => {
      searchParams.set('threadId', thread_id);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [navigate, location, searchParams],
  );

  const onExecuteCall = useCallback(async () => {
    setLoading(true);
    setQuestionValue('');
    createThreadAndMessage(
      { caseId: caseId || '', question: questionValue },
      {
        onSuccess: (data) => {
          if (data?.threadId) {
            const newThreadId = data?.thread_id;
            searchParams.set('threadId', newThreadId);
            setResponse(data);
            navigate(`${location.pathname}?${searchParams.toString()}`);
          } else {
            console.error('No thread_id returned from the server.');
          }
          setLoading(false);
        },
        onError: (error) => console.error('Error creating thread and message:', error),
      },
    );
  }, [questionValue, caseId, createThreadAndMessage, searchParams, navigate, location]);

  const threads = useMemo(() => responseThreads?.threads?.reverse(), [responseThreads]);

  return (
    <div className="w-full flex flex-row gap-2 h-[87%]">
      <div className="flex flex-col w-full border bg-white h-full items-center justify-center justify-items-center rounded-md border-opacity-40">
        {threadId && response ? (
          <Answer message={response} threadId={threadId} />
        ) : loading ? (
          <div>
            <FontAwesomeIcon icon={faCircleNotch} className="text-brandSecondary pb-1 fa-spin" />
          </div>
        ) : (
          <QuestionInput
            onExecuteCall={onExecuteCall}
            onChange={(v: string) => setQuestionValue(v)}
            value={questionValue}
          />
        )}
      </div>

      <div className="flex flex-col w-44 border bg-white pb-2 rounded-md border-opacity-40">
        <ThreadsBar
          threads={threads}
          createNewThread={handleNewThread}
          goToThread={goToThread}
          isLoadingThreads={isLoadingThreads}
        />
      </div>
    </div>
  );
});

export default CaseAssistant;
