import React, { useEffect, useState } from 'react';

import { Column, Updater } from '@tanstack/react-table';
import { ReactComponent as ReactFilter } from 'assets/filter.svg';
import { ReactComponent as ReactFilterActive } from 'assets/filter_active.svg';
import Select from 'react-select';

import { ChronosChronologyEvent, ChronosDoc, ChronosFact, FilterOption } from '../../../../../../types';
import { PlaceholderLabel, FILTER_OPTIONS } from '../filterOptions';

interface FilterPopupProps {
  column: Column<ChronosFact, unknown> | Column<ChronosDoc, unknown> | Column<ChronosChronologyEvent, unknown>;
  filterValue: FilterOption[];
}

const FilterPopup: React.FC<FilterPopupProps> = ({ column, filterValue = [] }) => {
  // @ts-ignore
  const options = FILTER_OPTIONS[column.id] || [];
  const deps = JSON.stringify(filterValue);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>(filterValue);
  const showActiveFilter = filterValue.length > 0 || isOpen;

  const filterUpdater: Updater<FilterOption[]> = (old) => {
    return selectedOptions;
  };

  const handleApply = () => {
    column.setFilterValue(filterUpdater);
    setIsOpen(false);
  };

  const onChange = (selected: any) => {
    setSelectedOptions(selected as FilterOption[]);
  };

  const onCancel = () => {
    setSelectedOptions(filterValue);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedOptions(filterValue);
    // eslint-disable-next-line
  }, [deps]);

  return (
    <div className="relative w-6 h-6 flex items-center justify-center">
      <div
        onClick={() => setIsOpen(!isOpen)}
        data-testid="filter-icon"
        className="cursor-pointer w-5 h-5 rounded-md flex items-center justify-center"
        style={{ background: showActiveFilter ? '#0865E0' : 'none' }}
      >
        {showActiveFilter ? <ReactFilterActive /> : <ReactFilter />}
      </div>
      {isOpen && (
        <div
          data-testid="filter-dropdown"
          className="absolute z-30 top-8 bg-white border border-gray-300 rounded shadow-xl shrink-0 w-52"
        >
          <div className="p-4">
            <Select
              isMulti
              value={selectedOptions}
              onChange={onChange}
              options={options}
              placeholder={`Select ${PlaceholderLabel[column.id]}`}
            />
            <div className="mt-3 flex justify-end space-x-2">
              <button data-testid="cancel-button" onClick={onCancel} className="text-gray-500 hover:text-gray-700">
                Cancel
              </button>
              <button onClick={handleApply} className="text-blue-500 hover:text-blue-700">
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPopup;
