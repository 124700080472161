import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useFetchCaseObject = (caseId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const { isFetching, data, refetch } = useQuery(
    'getCaseForDocAdder',
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    },
    {
      cacheTime: 0,
    },
  );

  return {
    isFetching,
    data,
    refetch,
  };
};

export default useFetchCaseObject;
