import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';

interface CreateMatterButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  onClick: () => void;
  text: string;
}

const CreateMatterButton = ({ isLoading, isDisabled, onClick, text }: CreateMatterButtonProps) => {
  return (
    <button
      className="cursor-pointer flex gap-2 justify-center items-center disabled:opacity-40 bg-brandPrimary rounded-lg hover:bg-brandSecondary-hoverdisabled:opacity-20 shrink-0 text-center text-base not-italic font-semibold text-white disabled:cursor-not-allowed px-6 py-3"
      onClick={onClick}
      disabled={isDisabled}
    >
      {isLoading ? <StageSpinner color={'white'} size={25} /> : text}
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  );
};

export default CreateMatterButton;
