import React, { useRef, useState, useEffect } from 'react';

import { faCheck, faChevronDown, faChevronUp, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IncludeAllDropdownProps {
  allRowsChecked: boolean;
  anyRowsChecked: boolean;
  handleSelectAll: () => void;
  handleClearAll: () => void;
}

const IncludeAllDropdown: React.FC<IncludeAllDropdownProps> = ({
  allRowsChecked,
  anyRowsChecked,
  handleSelectAll,
  handleClearAll,
}) => {
  // State
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const maxHeight = dropdownOpen ? `${dropdownContentRef.current?.scrollHeight}px` : '0px';

  // Effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handlers
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectAllHandler = async () => {
    if (allRowsChecked) return;
    setLoading(true);
    await handleSelectAll();
    setLoading(false);
    setDropdownOpen(false);
  };

  const clearAllHandler = async () => {
    if (!anyRowsChecked) return;
    setLoading(true);
    await handleClearAll();
    setLoading(false);
    setDropdownOpen(false);
  };

  const handleTickboxSelectAll = async () => {
    setLoading(true);
    if (allRowsChecked) {
      await handleClearAll();
    } else {
      await handleSelectAll();
    }
    setLoading(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center gap-2">
        {loading ? (
          <FontAwesomeIcon icon={faCircleNotch} spin className="text-brandSecondary h-4 w-4" />
        ) : (
          <div className="flex flex-row justify-center items-center w-auto">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="hidden peer h-4 w-4"
                onChange={handleTickboxSelectAll}
                checked={allRowsChecked}
              />
              <div className="w-4 h-4 rounded-full border border-gray-300 bg-buttonSecondary peer-checked:bg-brandSecondary flex justify-center items-center">
                <FontAwesomeIcon icon={faCheck} className="text-white peer-checked:inline-block h-2 w-2" />
              </div>
            </label>
          </div>
        )}

        <FontAwesomeIcon
          icon={dropdownOpen ? faChevronUp : faChevronDown}
          className="hover:cursor-pointer w-3 h-3 text-gray-400"
          onClick={() => toggleDropdown()}
        />
      </div>

      <div
        ref={dropdownContentRef}
        style={{
          marginTop: '0.5rem',
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
        }}
        // add border if dropdown is open
        className="text-sm absolute rounded-b-md shadow-lg w-36 z-10 bg-white "
      >
        <button
          className="disabled:cursor-not-allowed disabled:bg-gray-100 shadow-sm flex justify-center items-center px-2 py-3 not-italic w-full cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          onClick={() => selectAllHandler()}
          disabled={allRowsChecked}
        >
          Select All
        </button>
        <button
          className="disabled:cursor-not-allowed disabled:bg-gray-100 shadow-sm flex justify-center items-center px-2 py-3 not-italic w-full cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          onClick={() => clearAllHandler()}
          disabled={!anyRowsChecked}
        >
          Clear Selected
        </button>
      </div>
    </div>
  );
};

export default IncludeAllDropdown;
