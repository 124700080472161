export const getAPIBase = () => {
  const environment = process.env.REACT_APP_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://dev.api.wexler.ai';
    case 'test':
      return 'https://test.api.wexler.ai';
    case 'production':
      return 'https://api.wexler.ai';
    case 'local':
      return 'http://localhost:8081';
    default:
      return 'http://localhost:8081';
  }
};

export const getAPIBaseChronos = () => {
  const environment = process.env.REACT_APP_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://tc2rqni3gd.eu-west-2.awsapprunner.com';
    case 'production':
      return 'https://ctty2sm3ct.eu-west-2.awsapprunner.com';
    case 'test':
      return 'https://hmzwrdm5ki.eu-west-2.awsapprunner.com';
    case 'local':
      return 'http://localhost:8081';
    default:
      return 'http://localhost:8081';
  }
};

export const APIBase = getAPIBase();
export const APIBaseChronos = getAPIBaseChronos();
