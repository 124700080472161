import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { UsageResponse } from 'types';

const useFetchOrgData = (
  enabled: boolean,
  startDate?: string,
  endDate?: string,
  matterIds?: string[],
  userIds?: string[],
  allUsers?: { id: string; email: string }[],
  orgIds?: string[],
) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<UsageResponse>(
    ['fetchOrgData', enabled, startDate, endDate, matterIds, userIds, allUsers, orgIds],
    () => {
      const url = new URL(`${APIBaseChronos}/client/org/summaryData`);
      if (startDate) url.searchParams.append('startDate', startDate);
      if (endDate) url.searchParams.append('endDate', endDate);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: 'POST',
        body: JSON.stringify({
          matterIds: matterIds?.length ? matterIds : null,
          userIds,
          allUsers: allUsers ? allUsers : [],
          orgIds,
        }),
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: enabled,
    },
  );
};

export default useFetchOrgData;
