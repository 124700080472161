import React, { useState } from 'react';

import CopyAnimation from 'assets/animations/Copy.json';

import LottieAnimation from '../Lottie';

interface CopyProps {
  copyHandler: () => void;
}

const Copy = ({ copyHandler }: CopyProps) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const handleCopyClick = () => {
    setCopyClicked(true);
    copyHandler();
    setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
  };

  return (
    <div
      onClick={handleCopyClick}
      className="flex items-center gap-2 border hover:bg-gray-200 hover:cursor-pointer rounded-full px-3 py-2"
    >
      <LottieAnimation
        autoplay={copyClicked}
        animationData={CopyAnimation}
        height={15}
        width={15}
        loop={false}
        isStopped={!copyClicked}
      />

      <span className={`text-xs transition-all duration-100 ease-in-out ${copyClicked ? 'w-10' : 'w-7'}`}>
        {copyClicked ? 'Copied!' : 'Copy'}
      </span>
    </div>
  );
};

export default Copy;
