import React from 'react';

import Select, { ActionMeta, MultiValue } from 'react-select';
import { MyOptionType } from 'types';

interface MultiSelectProps {
  options: MyOptionType[];
  selectedOptions: MultiValue<MyOptionType>;
  onChange: (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void;
  placeholder: string;
  className?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedOptions,
  onChange,
  placeholder,
  className = '',
}) => {
  return (
    <Select
      options={options}
      isMulti={true}
      className={`outline-none bg-white rounded-md ${className}`}
      onChange={onChange}
      value={selectedOptions}
      placeholder={placeholder}
    />
  );
};

export default MultiSelect;
