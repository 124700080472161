import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

const useToggleIncluded = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const putIncludedToggle = useMutation(
    async ({ caseId, toggleValue, eventId }: { caseId: string; toggleValue: boolean; eventId: string }) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: { included: toggleValue, caseId },
      });
      const response = await fetch(`${APIBaseChronos}/client/case/fact/toggleIncluded/${eventId}`, fetchConfig);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  );

  return putIncludedToggle;
};

export default useToggleIncluded;
