import React, { useState } from 'react';

import Button from 'components/atoms/Button';
import moment from 'moment';
import Swal from 'sweetalert2';

import { MatterItem } from '../types';

export interface MatterItemComponentProps {
  item: MatterItem;
  isCreator: boolean;
  isSuperAdmin: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onView: (id: string) => void;
}

const MatterItemComponent: React.FC<MatterItemComponentProps> = ({
  item,
  isCreator,
  onEdit,
  onDelete,
  onView,
  isSuperAdmin,
}) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage text expansion
  const handleDelete = (matterId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(matterId);
      }
    });
  };

  return (
    <div className="bg-white text-gray-800 w-full rounded-lg px-8 py-8 flex items-start justify-start flex-col">
      {item.latest_matter_name && (
        <div className="text-xl not-italic font-semibold" onClick={() => onView(item.matter_id)}>
          {item.latest_matter_name}
        </div>
      )}
      {item.latest_matter_description && (
        <div
          className={`overflow-hidden text-sm not-italic font-normal leading-4 mt-3 ${
            isExpanded ? '' : 'line-clamp-3'
          }`}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {item.latest_matter_description}
        </div>
      )}
      <div className="overflow-hidden text-green-700 text-sm not-italic font-semibold leading-5 right-0 flex justify-between items-center mt-2">
        Date: {`${moment(item.created_date).format('MMMM DD, YYYY')}`}
      </div>
      <div className="flex flex-row gap-2 mt-5 w-full">
        <Button rounded="lg" size="small" text="View" type="primary" onClick={() => onView(item.matter_id)} />

        {(isCreator || isSuperAdmin) && (
          <Button rounded="lg" size="small" text="Edit" type="secondary" onClick={() => onEdit(item.matter_id)} />
        )}
      </div>
      {item.ultimate_edit_date && (
        <div className="px-2 py-1 rounded text-xs italic font-normal leading-4 flex items-start bg-yellow-200 bg-opacity-20 mt-3">
          {`Last updated by ${item.editor_email} on ${moment(item.ultimate_edit_date).format('DD/MM/YYYY')}`}
        </div>
      )}
      {/* Remove this?? */}
      {isCreator && false && (
        <div
          className=" cursor-pointer text-xs font-semibold flex items-center rounded-lg text-red-600 bg-red-200 h-8  px-4 ml-auto mb-3"
          onClick={() => handleDelete(item.matter_id)}
        >
          Delete matter
        </div>
      )}
    </div>
  );
};

export default MatterItemComponent;
