import { useEffect, useRef, useState } from 'react';

import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';
import { useMutation, useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';

import { renderHighlightedText } from './helpers';

const TooltipStylesComments = {
  display: 'flex',
  width: '300px',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  background: '#FFFFFF',
  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--colors-primary-slate-600, #455468)',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  zIndex: '100',
};

// Interfaces
interface TextInputCellProps {
  thread_id: string;
  comments_count: number;
  currentUserId: string;
}

interface Comment {
  comment_by_email: string;
  comment_by: string;
  comment_date: Date;
  comment: string;
  comment_id: string;
}

interface CommentsResponse {
  comments: Comment[];
}
interface User {
  id: string;
  display: string;
}

interface UserResponse {
  users: Array<{
    userId: string;
    email: string;
  }>;
}

const CommentPopup = ({ thread_id, comments_count, currentUserId }: TextInputCellProps) => {
  // State
  const [value, setValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [commentCount, setCommentCount] = useState<number>(comments_count);
  const [loadingNewComment, setLoadingNewComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);

  const tooltipRef = useRef(null);

  // Data fetching
  const { getFetchConfig, fetchConfigGET } = useGetFetchConfig();
  const { data: userData } = useQuery<User[], Error>(
    'org-users',
    () =>
      fetch(`${APIBaseChronos}/client/org/usersByEvent/${thread_id}`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json().then((data: UserResponse) =>
          data.users.map((user) => ({
            id: user.userId,
            display: user.email,
          })),
        );
      }),
    {
      enabled: isOpen, // Enable user query only when the tooltip is open
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: comments,
    isLoading,
    isError,
    refetch,
  } = useQuery<CommentsResponse, Error>(
    ['comments', thread_id], // Include thread_id in the query key to ensure unique caching per thread
    () =>
      fetch(`${APIBaseChronos}/client/case/fact/comment/${thread_id}`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json();
      }),
    {
      refetchOnWindowFocus: false,
      enabled: isOpen, // This line controls the execution of the query based on the isOpen state.
    },
  );

  // Comment operations
  const { mutate } = useMutation(
    (newComment: { comment: string }) => {
      setLoadingNewComment(true);
      Swal.fire({
        title: '',
        text: 'Adding comment',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          comment: newComment.comment,
          url: window.location.href,
        },
      });
      setLoadingNewComment(false);
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${thread_id}`, fetchConfig).then((res) => res.json());
    },
    {
      onSuccess: (data) => {
        refetch();
        setValue('');
        Swal.fire({
          title: '',
          text: 'Comment added successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        setCommentCount(commentCount + 1);
      },
      onError: (error) => {
        console.error('Error posting comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to post comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  const { mutate: editComment } = useMutation(
    (updatedComment: { comment: string }) => {
      Swal.fire({
        title: '',
        text: 'Updating comment...',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          comment: updatedComment.comment,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${editingCommentId}`, fetchConfig).then((res) =>
        res.json(),
      );
    },
    {
      onSuccess: () => {
        refetch();
        setValue('');
        setEditingCommentId(null);
        Swal.fire({
          title: '',
          text: 'Comment updated successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
      onError: (error) => {
        console.error('Error updating comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to update comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  const { mutate: deleteComment } = useMutation(
    (commentId: string) => {
      Swal.fire({
        title: '',
        text: 'Deleting comment...',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'DELETE',
      });
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${commentId}`, fetchConfig).then((res) => res.json());
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire({
          title: '',
          text: 'Comment deleted successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        setCommentCount(commentCount - 1);
      },
      onError: (error) => {
        console.error('Error deleting comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to delete comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  // Handlers
  const handleEditComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can edit it
    setValue(comment.comment);
    setEditingCommentId(comment.comment_id);
    setIsOpen(true);
  };

  const handleSubmitCommentUpdate = () => {
    editComment({ comment: value });
  };

  const handleCancelUpdate = () => {
    setValue('');
    setEditingCommentId('');
  };

  const handleDeleteComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can delete it
    deleteComment(comment.comment_id);
  };

  const handleSubmitComment = () => {
    if (!value) return;
    mutate({ comment: value });
  };

  // Effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (tooltipRef.current && (event.target as Node) && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setCommentCount(comments_count);
  }, [comments_count]);

  return (
    <div className="flex flex-row items-center justify-start font-medium text-xs">
      <div
        data-tooltip-id={`comment-thread-${thread_id}`}
        onClick={() => setIsOpen(!isOpen)}
        className="py-2 relative h-full text-blue-500 border flex items-center gap-2 px-2 rounded shadow bg-gray-50 hover:bg-gray-100 hover:cursor-pointer hover:shadow-none"
      >
        Comments {comments_count ? `(${comments_count})` : comments_count}
        <FontAwesomeIcon icon={faComment} className="h-4 w-4 " />
      </div>

      <Tooltip
        id={`comment-thread-${thread_id}`}
        style={{ ...TooltipStylesComments }}
        place={'left'}
        isOpen={isOpen}
        opacity={1}
        clickable
      >
        <div ref={tooltipRef} className="w-full">
          {isLoading ? (
            <div>Loading comments...</div>
          ) : isError ? (
            <div>Error loading comments.</div>
          ) : (
            <div className="flex flex-col w-full items-start justify-center">
              <div className=" max-h-96 overflow-y-scroll w-full">
                {!!comments?.comments?.length &&
                  [...comments?.comments].reverse()?.map((comment) => {
                    return (
                      <div className="flex flex-col mb-2 border border-gray-100 shadow-sm rounded p-2">
                        <div className="text-sm font-semibold">{comment.comment_by_email}</div>
                        <div className="text-xs text-gray-400 mb-1">{`${moment(
                          comment.comment_date || Date.now(),
                        ).format('MMMM DD, YYYY')}`}</div>
                        {editingCommentId !== comment.comment_id ? (
                          <div className="text-gray-800">{renderHighlightedText(comment.comment)}</div>
                        ) : (
                          <MentionsInput
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            className="w-full h-12 rounded border p-2"
                            placeholder={"Mention people using '@'"}
                            a11ySuggestionsListLabel={'Suggested mentions'}
                          >
                            <Mention
                              data={userData || []}
                              trigger="@"
                              style={{
                                backgroundColor: '#EEEEEE',
                              }}
                            />
                          </MentionsInput>
                        )}
                        {comment.comment_by === currentUserId && (
                          <div className="flex flex-row">
                            {!editingCommentId && (
                              <>
                                <button className="text-blue-500 mr-2" onClick={() => handleEditComment(comment)}>
                                  Edit
                                </button>
                                <button className="text-red-500" onClick={() => handleDeleteComment(comment)}>
                                  Delete
                                </button>
                              </>
                            )}
                            {editingCommentId === comment.comment_id && (
                              <>
                                <button className="text-blue-500 mr-2" onClick={handleSubmitCommentUpdate}>
                                  Update
                                </button>
                                <button className="text-red-500" onClick={handleCancelUpdate}>
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              {!editingCommentId && (
                <>
                  <MentionsInput
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                    className="w-full h-12 rounded border p-2"
                    placeholder={"Mention people using '@'"}
                    a11ySuggestionsListLabel={'Suggested mentions'}
                  >
                    <Mention
                      data={userData || []}
                      trigger="@"
                      style={{
                        backgroundColor: '#EEEEEE',
                      }}
                    />
                  </MentionsInput>
                  <div className="flex flex-row justify-between w-full mb-2 mt-4">
                    <button
                      className="flex justify-center items-center px-2 py-1 hover:bg-blue-700 bg-blue-500 text-white rounded not-italic font-bold cursor-pointer disabled:cursor-not-allowed"
                      onClick={handleSubmitComment}
                    >
                      {loadingNewComment ? <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> : 'Comment'}
                    </button>
                    <button
                      className="flex justify-center items-center px-2 py-1  hover:bg-red-100 rounded border text-red-400 border-red-400 not-italic font-bold cursor-pointer disabled:cursor-not-allowed"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CommentPopup;
