import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { ChronosRun } from 'types';

import DateCell from './DateCell';

export const columnLabels: Record<string, string> = {};

const useGetDocumentColumns = () => {
  const columns = useMemo<ColumnDef<ChronosRun, any>[]>(
    () => [
      {
        header: () => <div className="flex flex-row items-center">Run</div>,
        size: 50,
        accessorKey: 'item_number',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { item_number },
            },
          },
        }: any) => <div className="pl-2 flex flex-row items-center justify-start w-10">{item_number + 1}</div>,
      },
      {
        header: () => <div className="flex flex-row items-center">Status</div>,
        accessorKey: 'pipeline_status',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { pipeline_status, in_queue, queue_position },
            },
          },
        }: any) => (
          <div className="w-44">
            {in_queue ? `In queue, position ${queue_position}` : PIPELINE_STATUS[pipeline_status]?.label}
          </div>
        ),
      },
      {
        header: () => <div className="flex flex-row items-center">Estimated End Time</div>,
        accessorKey: 'estimate_date',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { time_estimate_datetime },
            },
          },
        }: any) => <DateCell format={'DD MMMM YYYY'} date={time_estimate_datetime} />,
      },
    ],

    [],
  );
  return columns;
};

export default useGetDocumentColumns;
