import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

const useToggleAll = () => {
  const { getFetchConfig } = useGetFetchConfig();

  // Define the mutation
  const putIncludedToggle = useMutation(
    async ({ caseId, toggleValue, filterParams }: { caseId: string; toggleValue: boolean; filterParams: string }) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: { included: toggleValue, caseId },
      });

      const response = await fetch(`${APIBaseChronos}/client/case/fact/toggleAllIncluded?${filterParams}`, fetchConfig);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  );

  return putIncludedToggle;
};

export default useToggleAll;
