import React, { useState, useCallback, useMemo } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosDoc, MyOptionType } from 'types';

interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  chronology_id: string;
}
const FactAdder = ({ handleNewFactCreated, docs, chronology_id }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const { getFetchConfig } = useGetFetchConfig();

  const [description, setDescription] = useState<string>('');
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');

  const fetchConfig = getFetchConfig({
    method: 'POST',
    data: {
      longer_description: description,
      fact_date_text: dateOfSubjectMatterText,
      chronology_id: chronology_id,
      relevance_reason: significance,
    },
  });

  const { isFetching: isLoadingAddNewFact, refetch: refetchAddNewFact } = useQuery(
    ['addFactQuery', selectedDoc?.value],
    () => {
      return fetch(`${APIBaseChronos}/client/case/chronology/${selectedDoc?.value}`, fetchConfig)
        .then((res) => {
          Swal.fire({
            title: 'New fact created succesfully',
            text: 'A new fact was created succesfully',
            showConfirmButton: false,
            timer: 2500,
          });
          handleNewFactCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while adding new fact',
            text: 'There was an error while adding new fact. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }, []);

  const handleChangeSelectedDoc = useCallback((val: MyOptionType) => {
    setSelectedDoc(val);
  }, []);

  const handleChangeDateOfSubjectMatterText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  }, []);

  const handleChangeSignificance = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  }, []);

  const docsOptions = useMemo(
    () =>
      docs.map((doc) => ({
        value: doc.doc_id,
        label: doc.file_name,
      })),
    [docs],
  );

  const handleAddFact = () => {
    refetchAddNewFact();
  };

  const disabled = !selectedDoc;
  return (
    <div style={{ width: '350px', maxHeight: '70vh', paddingTop: '10px', paddingBottom: '10px' }}>
      <div className="h-12 pl-3 w-full focus:outline-none text-black text-3xl not-italic font-bold placeholder:text-gray-400 rounded-lg bg-white mt-4">
        New Fact
      </div>
      <input
        onChange={handleChangeDescription}
        className="h-12 pl-3 mt-2 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={description}
        placeholder="Description"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      <Select
        options={docsOptions}
        className="outline-none w-full bg-white rounded-md mt-3"
        styles={reactSelectStylesFormFact}
        onChange={handleChangeSelectedDoc}
        value={selectedDoc}
        placeholder={'Select a document'}
      />
      <input
        onChange={handleChangeDateOfSubjectMatterText}
        className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={dateOfSubjectMatterText}
        placeholder="Date"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      <input
        onChange={handleChangeSignificance}
        className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={significance}
        placeholder="Significance"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      <button
        disabled={disabled}
        onClick={() => {
          !disabled && handleAddFact();
        }}
        className="flex justify-center items-center px-2 py-3 rounded-lg mt-3   not-italic font-bold w-56 cursor-pointer ml-auto disabled:bg-gray-500 disabled:opacity-20 disabled:cursor-not-allowed mb-12"
        style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
      >
        {isLoadingAddNewFact ? <StageSpinner className="m-auto" color={'#4161FF'} size={25} /> : 'Add'}
      </button>
    </div>
  );
};

export default FactAdder;
