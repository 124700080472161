import React from 'react';

import { ChronosChronologyEvent } from 'types';

import ChronologyDropdown from '../components/ChronologyDropdown';

interface ChronologyTimelineProps {
  chronology: ChronosChronologyEvent[];
}

const ChronologyTimeline: React.FC<ChronologyTimelineProps> = ({ chronology }) => {
  return (
    <div className="relative flex flex-col gap-4 pr-4">
      {/* Vertical line */}
      <div className="absolute left-4 top-0 h-full border-l-2 border-gray-300"></div>

      {chronology.map((event: ChronosChronologyEvent, index: number) => (
        <div key={index} className="">
          <div className="flex items-center">
            {/* Marker */}
            <div className="w-4 h-4 rounded-full absolute left-[9px] bg-white border-2 border-brandSecondary"></div>
            {/* Fact content */}
            <div
              key={event.event_id}
              id={event.event_id}
              className="ml-10 border-2 border-opacity-40 rounded w-full relative"
            >
              <ChronologyDropdown event={event} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChronologyTimeline;
