import React, { useState, useEffect } from 'react';

import {
  faHashtag,
  faFile,
  faMagnifyingGlass,
  faTimeline,
  faChartSimple,
  faArrowUpRightFromSquare,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Copy from 'components/atoms/Copy';
import Accordion from 'components/molecules/Accordion';
import CustomModal from 'components/molecules/Modals/Custom';
import Modal from 'components/molecules/Modals/Custom';
import PageMissing from 'components/molecules/PageMissing';
import { THEME_STEPS } from 'constants/tours';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Tour from 'reactour';

import ActionButton from './components/ActionButton';
import ChronologyModal from './components/ChronologyModal';
import KeyFactsTimeline from './components/KeyFactsTimeline';
import RelevantDocs from './components/RelevantDocs';
import StatsTable from './components/StatsTable';
import ThemeContent from './components/ThemeContent';
import { SummaryTooltip, ThemeTooltip } from './components/TooltipModals';
import formatSummary from './helpers/formatHTML';
import useGetCaseOverview from './hooks/useGetCaseOverview';
import useGetSummary from './hooks/useGetSummary';

const CaseSummary = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // State
  const [chronologyExplorerOpen, setChronologyExplorerOpen] = useState(false);
  const [themeTitles, setThemeTitles] = useState<{ title: string; message_id: string }[]>([]);
  const [themeText, setThemeText] = useState<string[]>([]);
  const [summaryText, setSummaryText] = useState<string>('');
  const [themeSubquestions, setThemeSubQuestions] = useState<{
    [message_id: string]: { title: string; message_content: string }[];
  }>({});
  const [themeTourOpen, setThemeTourOpen] = useState(false);
  const [userId, setCurrentUserId] = useState('');
  const [tooltipModalOpen, setTooltipModalOpen] = useState(false);
  const [tooltipModalContent, setTooltipModalContent] = useState(<></>);

  // Hooks
  const { data: summaries, isLoading: summaryIsLoading, refetch: refetchSummary } = useGetSummary(caseId);
  const { data: overview, isLoading: overviewIsLoading, refetch: refetchOverview } = useGetCaseOverview(caseId);
  const { fetchConfigGET } = useGetFetchConfig();
  const location = useLocation();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    refetchSummary();
    refetchOverview();
  }, [caseId, refetchSummary, refetchOverview]);

  useEffect(() => {
    if (!summaries) return;

    const rawThemes = summaries.summaries.filter((summary: any) => summary.title.toLowerCase() !== 'overview');

    const themeTitles = rawThemes.map((summary: any) => ({
      title: summary.title,
      message_id: summary.message_id,
    }));

    const themeTexts = rawThemes.map((summary: any) =>
      summary.message_content
        .replace(/<b>.*?<\/b>/g, '') // Remove anything between <b> tags
        .replace(/<br>/g, ' ') // Replace all <br> tags with a space
        .trim(),
    );

    setThemeTitles(themeTitles);
    setThemeText(themeTexts);
    setThemeSubQuestions(summaries.subQuestions);

    const overviewSummary = summaries.summaries.find((summary: any) => summary.title.toLowerCase() === 'overview');

    if (overviewSummary) {
      const overviewText = overviewSummary.message_content
        .replace(/<b>.*?<\/b>/g, '')
        .replace(/<br>/g, ' ')
        .trim();
      setSummaryText(overviewText);
    }
  }, [summaries]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await fetch(`${APIBaseChronos}/client/user/user`, fetchConfigGET);
        if (!res.ok) throw new Error('Network response was not ok');
        const data = await res.json();
        setCurrentUserId(data.user.userId);
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };

    fetchCurrentUser();
  }, [fetchConfigGET]);

  // Handlers
  const scrollToRow = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Apply classes for visual effect
      element.classList.add('relative', 'z-30', 'animate-pulse-border-green');
      setTimeout(() => {
        element.classList.remove('animate-pulse-border-green', 'z-30', 'relative');
      }, 2000); // How long we pulse for
    }
  };

  const handleViewKeyFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    searchParams.set('viewKeyFacts', 'true');

    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  const handleViewFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  const handleViewDocs = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/documents?' + searchParams.toString());
  };

  const handleRelevantDocClick = (docId: string) => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('docId', docId);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/summary?' + searchParams.toString());
  };

  const handleCopyClick = (content: string) => {
    const text = content
      .replace(/\[.*?\]/g, '')
      .replace(/<b>/g, '')
      .replace(/<\/b>/g, '')
      .replace(/<br>/g, '\n');
    navigator.clipboard.writeText(text);
  };

  const handleExploreClicked = () => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('explore', 'true');
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  const handleDisplayTooltipModal = (content: string) => {
    if (content === 'Summary') {
      setTooltipModalContent(<SummaryTooltip />);
    } else if (content === 'Themes') {
      setTooltipModalContent(<ThemeTooltip />);
    }
    setTooltipModalOpen(true);
  };

  return (
    <div className="flex flex-col pb-4 leading-relaxed">
      {summaryIsLoading || overviewIsLoading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner className="m-auto" color={'rgb(37 99 235)'} />
        </div>
      ) : (!overview || !summaries) && !summaryIsLoading && !overviewIsLoading ? (
        <PageMissing
          title="Summary Not Found"
          description={
            <div className="text-sm mt-4">
              Sorry! We couldn't find a summary for this case. Please{' '}
              <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
                contact
              </a>{' '}
              Wexler for support.
            </div>
          }
        />
      ) : (
        <>
          {summaryText ? (
            <>
              <div className="flex mb-8">
                <div className="rounded-md bg-white border-2 border-gray-200 border-opacity-60 p-4">
                  <div className="font-semibold mb-2 flex items-center">
                    Summary
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-gray-400 hover:text-blue-500 w-3 h-3 cursor-pointer ml-2"
                      onClick={() => handleDisplayTooltipModal('Summary')}
                    />
                  </div>
                  <div className="text-sm leading-relaxed">{formatSummary(summaryText, scrollToRow)}</div>
                  <div className="text-gray-800 flex gap-2 mt-4">
                    <Copy copyHandler={() => handleCopyClick(summaryText)} />
                  </div>
                </div>
              </div>

              <div className="flex justify-between w-full gap-8 mb-10">
                <div className="relative bg-white p-4 border-2 border-gray-200 border-opacity-40 rounded-lg py-4 w-5/12">
                  <h2 className="font-semibold mb-2 border-2 border-x-0 border-t-0 pb-1">Explore</h2>
                  <div className="text-sm text-gray-800 flex flex-col gap-4 w-full ">
                    {themeTitles.length ? (
                      <ActionButton
                        icon={
                          <FontAwesomeIcon
                            icon={faHashtag}
                            className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                          />
                        }
                        title="Analyse key themes"
                        description="Examine key themes extracted from the case facts."
                        onClick={() => setThemeTourOpen(true)}
                        index={0}
                      />
                    ) : null}
                    <ActionButton
                      icon={
                        <FontAwesomeIcon
                          icon={faTimeline}
                          className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                        />
                      }
                      title="Create chronology"
                      description="Construct a timeline of key events."
                      onClick={() => setChronologyExplorerOpen(true)}
                      index={1}
                    />
                    <ActionButton
                      icon={
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                        />
                      }
                      title="Explore the facts"
                      description="Use our agent to search and interrogate your documents."
                      onClick={handleExploreClicked}
                      index={2}
                    />
                  </div>
                </div>

                <div className="relative white p-4 border-2 bg-white border-gray-200 border-opacity-60 rounded-lg py-4 w-3/4">
                  <h2 className="font-semibold mb-4 border-2 border-x-0 border-t-0 pb-1">Overview</h2>
                  <div className="flex gap-4 w-full text-sm text-gray-600 px-1">
                    <div className="flex flex-col gap-4 w-1/3 border-2 border-y-0 border-l-0 pr-10">
                      <p className="text-base font-medium border border-x-0 border-t-0">
                        <FontAwesomeIcon icon={faChartSimple} className="text-gray-800 w-4 h-4 mr-2" />
                        Totals
                      </p>
                      <StatsTable
                        stats={{
                          factsExtracted: overview?.caseOverview?.totalFacts || 0,
                          relevantFacts: overview?.caseOverview?.relevantFacts || 0,
                          keyFacts: overview?.caseOverview?.keyFacts || 0,
                          documents: overview?.caseOverview?.documents || 0,
                          pages: overview?.caseOverview?.pages || 0,
                        }}
                        onClickHandlers={{
                          factsExtracted: handleViewFacts,
                          relevantFacts: handleViewFacts,
                          keyFacts: handleViewKeyFacts,
                          documents: handleViewDocs,
                          pages: handleViewDocs,
                        }}
                        tooltips={{
                          factsExtracted: 'Total number of facts extracted from the documents.',
                          relevantFacts: 'Number of facts deemed relevant to the case synopsis.',
                          keyFacts: 'Number of key facts extracted from the documents.',
                          documents: 'Total number of documents in the case.',
                          pages: 'Total number of pages across the documents in the case.',
                        }}
                      />
                    </div>

                    <div className="text-sm flex flex-col gap-4 w-2/3 pl-4">
                      <p className="text-base font-medium border border-x-0 border-t-0 flex items-center">
                        <FontAwesomeIcon icon={faFile} className="text-gray-800 w-4 h-4 mr-2" />
                        Most Relevant Documents
                        <FontAwesomeIcon
                          data-tooltip-id="relevant-docs"
                          data-tooltip-content="The top 3 most relevant documents based on the number of relevant facts extracted from them."
                          icon={faInfoCircle}
                          className="text-gray-400 w-3 h-3 cursor-pointer ml-2"
                        />
                        <Tooltip
                          id="relevant-docs"
                          opacity={1}
                          style={{
                            width: '400px',
                            textAlign: 'center',
                            padding: '8px',
                            borderRadius: '8px',
                            backgroundColor: 'white',
                            color: 'black',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            letterSpacing: '-0.3px',
                            zIndex: 1,
                          }}
                        />
                      </p>
                      <RelevantDocs docs={overview?.caseOverview?.docEvents} onClick={handleRelevantDocClick} />
                    </div>
                  </div>
                </div>
              </div>
              {themeTitles.length ? (
                <div className="mb-8 bg-white p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
                  <div id="themes" className="font-semibold mb-2 flex items-center ">
                    Themes
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-gray-400 hover:text-blue-500 w-3 h-3 cursor-pointer ml-2"
                      onClick={() => handleDisplayTooltipModal('Themes')}
                    />
                  </div>
                  <div className="flex flex-col border-2 border-gray-200 border-opacity-60 rounded-md">
                    {themeTitles.map((themeTitle, index) => (
                      <Accordion
                        id={`theme-${index}`}
                        key={index}
                        title={<p className="text-blue-500 pr-12 font-medium">{themeTitle.title}</p>}
                        content={
                          <ThemeContent
                            key={index}
                            id={`theme-${index}`}
                            subquestions={themeSubquestions[themeTitle.message_id]}
                            content={themeText[index]}
                            scrollToRow={scrollToRow}
                          />
                        }
                        className="border-b p-4 bg-white"
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {summaries && summaries.keyFacts && summaries.keyFacts.length > 0 && (
            <>
              <div className="mb-4 bg-white p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
                <div className="flex items-center gap-1 w-full mb-3">
                  <div className="font-semibold flex items-center">Key Facts</div>

                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="text-blue-500 w-4 h-4 cursor-pointer p-2 hover:bg-gray-100 rounded-full"
                    onClick={handleViewKeyFacts}
                  />
                </div>
                <KeyFactsTimeline
                  factProps={summaries.keyFacts}
                  userId={userId}
                  references={summaries.eventIdReferences}
                />
              </div>
            </>
          )}
          <Tour startAt={0} steps={THEME_STEPS} isOpen={themeTourOpen} onRequestClose={() => setThemeTourOpen(false)} />
          {/*Chronology Modal*/}
          <Modal
            handleClose={() => {
              setChronologyExplorerOpen(false);
            }}
            isOpen={chronologyExplorerOpen}
            content={
              <ChronologyModal
                totalKeyFacts={overview?.caseOverview?.keyFacts}
                totalRelevantFacts={overview?.caseOverview?.relevantFacts}
                caseId={caseId}
              />
            }
          />
          {/*Tooltip Modal*/}
          <CustomModal
            handleClose={() => {
              setTooltipModalOpen(false);
            }}
            isOpen={tooltipModalOpen}
            content={tooltipModalContent}
          />
        </>
      )}
    </div>
  );
};

export default CaseSummary;
