import React from 'react';

import Select, { ActionMeta, SingleValue } from 'react-select';
import { FilterOption } from 'types';

interface SelectProps {
  options: FilterOption[];
  selectedOption: SingleValue<FilterOption>;
  onChange: (newValue: SingleValue<FilterOption>, actionMeta: ActionMeta<FilterOption>) => void;
  placeholder: string;
  className?: string;
}

const SingleSelect: React.FC<SelectProps> = ({ options, selectedOption, onChange, placeholder, className = '' }) => {
  return (
    <Select
      options={options}
      className={className}
      onChange={onChange}
      value={selectedOption}
      placeholder={placeholder}
    />
  );
};

export default SingleSelect;
