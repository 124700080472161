import React, { useState } from 'react';

import { faLightbulb, faCircle, faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Copy from 'components/atoms/Copy';
import TooltipIcon from 'components/atoms/TooltipIcon';

import FactDropdown from '../../CaseSummary/components/KeyFactsTimeline/components/FactDropdown';

interface Message {
  type: string;
  msg: string;
  trafficLight: string;
  answer: string;
  facts: any[];
  chunks: any[];
  threadId: string;
  question: string;
}
interface ConversationProps {
  isLoadingMessages?: boolean;
  message: Message;
  threadId: string;
  handleCompletePipe?: () => void;
}

const Conversation: React.FC<ConversationProps> = ({ message, threadId }) => {
  const getConfidenceLevel = (trafficLight: string) => {
    switch (trafficLight) {
      case 'green':
        return {
          borderColor: 'border-green-500',
          backgroundColor: 'bg-green-50',
          textColor: 'text-green-500',
          text: 'High',
          tooltip:
            'Kim has high confidence in the answer because it has found supporting facts and document text to support this answer.',
        };
      case 'amber':
        return {
          borderColor: 'border-yellow-500',
          backgroundColor: 'bg-yellow-50',
          textColor: 'text-yellow-500',
          text: 'Medium',
          tooltip:
            'Kim has medium confidence in the answer and reccommends double checking the output, it has found some supporting information.',
        };
      case 'red':
        return {
          borderColor: 'border-red-500',
          backgroundColor: 'bg-red-50',
          textColor: 'text-red-500',
          text: 'Low',
          tooltip:
            'Kim has low confidence in the answer and does not consider this a satisfactory answer. Some supporting information may be present.',
        };
      default:
        return { color: 'gray', text: 'Unknown', tooltip: '' };
    }
  };

  const confidenceLevel = getConfidenceLevel(message.trafficLight);

  const DocumentChunk = ({ chunk }: { chunk: any }) => {
    return (
      <div className="border-2 p-2 rounded" key={chunk.chunk_id}>
        <div className="flex justify-between">
          <div className="font-semibold flex gap-2 items-center">
            <div className="flex justify-between items-center">
              <div className="flex gap-2">
                <div className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden ">
                  {chunk.file_name}
                </div>
                <span className="px-2 rounded-sm bg-brandTertiary bg-opacity-30 text-gray-600">
                  {chunk.document_type}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="font-semibold mt-1 mb-1">Source Text</div>
        <p>{chunk.chunk_text}</p>
      </div>
    );
  };

  const formatAnswer = (answer: string) => {
    return answer.split(/(\[\d+\])/).map((part, index) => {
      if (/^\[\d+\]$/.test(part)) {
        return (
          <span key={index} className="text-blue-500">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const [showAllFacts, setShowAllFacts] = useState(false);
  const [showAllChunks, setShowAllChunks] = useState(false);

  return (
    <div className="h-full overflow-y-scroll py-4 px-4">
      <h1 className="mb-4 font-bold text-lg border-b w-fit pr-8">
        {message.question.charAt(0).toUpperCase() + message.question.slice(1)}
      </h1>
      <div className="flex items-center gap-2 mb-1">
        <FontAwesomeIcon icon={faLightbulb} className="text-brandSecondary pb-1" />
        <h2 className="text-base font-semibold text-gray-800">Answer</h2>
      </div>
      <div className="border-2 px-4 py-3 rounded shadow">
        <p className="text-sm text-gray-800">{formatAnswer(message.answer)}</p>
        <div className="flex mt-4 justify-between">
          <Copy
            copyHandler={() => {
              navigator.clipboard.writeText(message.answer);
            }}
          />

          <div
            className={`relative ${confidenceLevel.backgroundColor} shadow text-sm rounded px-2 border ${confidenceLevel.borderColor} flex items-center gap-2 border`}
          >
            <p>
              Confidence: <b>{confidenceLevel.text}</b>
            </p>
            <FontAwesomeIcon icon={faCircle} className={`${confidenceLevel.textColor}`} />
            <TooltipIcon
              place="left"
              tooltipId="confidence-level-tooltip"
              tooltipContent={confidenceLevel.tooltip}
              className="bg-gray-200 rounded-full mr-2 text-sm cursor-pointer ml-2 absolute top-[-10px] right-[-16px]"
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faBook} className="text-brandSecondary pb-1" />
          <h2 className="text-base font-semibold text-gray-800">Supporting Evidence</h2>
        </div>

        {message.facts && message.facts.length ? (
          <div className="mb-2 mt-1">
            <div className="font-medium mb-1 text-base">Events</div>
            <div className="flex flex-col gap-2">
              {message.facts.slice(0, showAllFacts ? undefined : 5).map((fact) => {
                return (
                  <div className="border-2 rounded" key={fact.reference}>
                    <FactDropdown
                      fact={fact}
                      userId=""
                      compressedVersion={true}
                      reference={fact.reference}
                      hideRelevanceIndicator={true}
                    />
                  </div>
                );
              })}
            </div>
            {message.facts.length > 5 && (
              <button
                className="mt-2 text-blue-600 hover:text-blue-800 font-medium text-sm"
                onClick={() => setShowAllFacts(!showAllFacts)}
              >
                {showAllFacts ? 'Show Less' : `Show ${message.facts.length - 5} More...`}
              </button>
            )}
          </div>
        ) : null}
        {message.chunks && message.chunks.length ? (
          <div className="my-2 mt-6 text-xs">
            <div className="font-medium mb-1 text-base">Source Texts...</div>
            <div className="flex flex-col gap-2">
              {message.chunks.slice(0, showAllChunks ? undefined : 0).map((chunk) => (
                <DocumentChunk chunk={chunk} key={chunk.chunk_id} />
              ))}
            </div>
            {message.chunks.length > 0 && (
              <button
                className="mt-2 text-blue-600 hover:text-blue-800 font-medium text-sm"
                onClick={() => setShowAllChunks(!showAllChunks)}
              >
                {showAllChunks ? 'Show Less' : `Show ${message.chunks.length}...`}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Conversation);
