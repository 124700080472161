import { rankItem } from '@tanstack/match-sorter-utils';
import { FilterFn } from '@tanstack/react-table';
import { ChronosDoc, FilterOption } from 'types';

interface FilterFns {
  fuzzyFilter: FilterFn<ChronosDoc>;
  boolean: FilterFn<ChronosDoc>;
}

export const filterFns: FilterFns = {
  fuzzyFilter: (row, columnId, filterValue: string, addMeta) => {
    if (!filterValue.length) return true;
    const itemRank = rankItem(row.getValue(columnId), filterValue);

    addMeta({
      itemRank,
    });

    const wordsArray = filterValue.split(' ').filter((word) => word.trim() !== '');
    const cellValue = (row.getValue(columnId) as string) || '';
    const isWordPresent = wordsArray.every((word) => cellValue.toLowerCase().includes(word.toLowerCase()));
    return isWordPresent;
  },
  boolean: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;
    const rowValue = row.getValue(columnId);

    return filterValue.some((filter) => filter.value === rowValue);
  },
};
