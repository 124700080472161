import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import TooltipIcon from 'components/atoms/TooltipIcon';
import Pagination from 'PrivateApp/Chronos/Pagination';

import DataViewToolbar from '../../components/DataViewToolbar';
import SearchBox from '../../components/SearchBox';

interface DocumentEditorToolbarProps {
  processedCount: number;
  totalDocs: number;
  openSettingsModal: () => void;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onSearchCall: () => void;
  clearSearch: () => void;
  table: any;
}

const DocumentEditorToolbar = ({
  processedCount,
  totalDocs,
  openSettingsModal,
  globalFilter,
  setGlobalFilter,
  onSearchCall,
  clearSearch,
  table,
}: DocumentEditorToolbarProps) => {
  return (
    <DataViewToolbar
      titleSection={
        <>
          <h1 className="text-lg font-semibold">Documents </h1>
          <div className="text-xs font-normal border pl-2 pr-1 bg-gray-50 border-brandSecondary rounded border-opacity-60 flex items-center gap-1 w-fit">
            <span>
              <b>
                {processedCount}/{totalDocs}
              </b>{' '}
              processed
            </span>
            {processedCount !== totalDocs && (
              <TooltipIcon
                className="h-3 w-3"
                tooltipId="add-facts-tooltip"
                tooltipContent="Add facts manually from unprocessed documents"
              />
            )}
          </div>
        </>
      }
      actionButtons={
        <Button
          onClick={openSettingsModal}
          className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
          text="Add Documents"
          icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
        />
      }
      searchBar={
        <SearchBox
          value={globalFilter}
          placeholder={`Search ${totalDocs} ${totalDocs > 1 ? 'documents' : 'document'}`}
          onChange={setGlobalFilter}
          onSearchCall={onSearchCall}
          clearSearch={clearSearch}
        />
      }
      pagination={
        <Pagination
          canGetPrevPage={table.getCanPreviousPage()}
          canGetNextPage={table.getCanNextPage()}
          prevPage={table.previousPage}
          nextPage={table.nextPage}
          currentPage={table.getState().pagination.pageIndex}
          noOfPages={table.getPageCount()}
          goToPage={table.setPageIndex}
        />
      }
    />
  );
};

export default DocumentEditorToolbar;
