interface PlainTextContentProps {
  html: string;
  maxLength: number;
}

export const PlainTextContent: React.FC<PlainTextContentProps> = ({ html, maxLength = 100 }) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  let textContents = Array.from(doc.body.childNodes)
    .map((node) => node.textContent?.trim()) // Extract the text content of each node
    .filter((text): text is string => Boolean(text)); // Remove any null or undefined text content

  // Join the text content, separating each with a comma
  let joinedText = textContents.join(', ');

  // If the text exceeds the maxLength, truncate and add an ellipsis
  if (joinedText.length > maxLength) {
    joinedText = `${joinedText.substring(0, maxLength - 3)}...`;
  }

  return <p className="break-all">{joinedText}</p>;
};

export const renderHighlightedText = (text: string) => {
  // Regex to match @[****](XXXXX)
  const regex = /@\[(.*?)\]\(.*?\)/g;
  const parts = text.split(regex);
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // This means it's the matched group which is ****
      return (
        <span key={index} className="bg-yellow-200 px-1 rounded">
          {part}
        </span>
      );
    }
    return part; // Normal text not inside @[****]
  });
};
