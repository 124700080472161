import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { UserObject } from '../types/user';

interface userCreateMatterProps {
  matterName: string;
  matterDescription: string;
  selectedUsers: UserObject[];
}

const useCreateMatter = ({ matterName, matterDescription, selectedUsers }: userCreateMatterProps) => {
  const { getFetchConfig } = useGetFetchConfig();
  const fetchCreateMatter = () => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        matterName,
        matterDescription,
        userIds: selectedUsers.map((x) => x.user_id),
      },
    });

    return fetch(`${APIBaseChronos}/client/case/matter`, fetchConfig)
      .then((res) => res.json())
      .catch((error) => console.error('Error creating matter:', error));
  };

  return useQuery(['createMatter'], fetchCreateMatter, {
    enabled: false,
  });
};

export default useCreateMatter;
