import React from 'react';

import StatisticItem from 'components/atoms/StatisticItem';

interface StatisticsGridProps {
  isFetching: boolean;
  data: {
    pageCountTotal: number;
    runCountTotal: number;
    chronologyCountTotal: number;
    eventCountTotal: number;
  };
}

const StatisticsGrid: React.FC<StatisticsGridProps> = ({ isFetching, data }) => (
  <div className="w-full flex flex-row gap-6">
    <StatisticItem title="Number of pages processed" value={data.pageCountTotal} isFetching={isFetching} />
    <StatisticItem title="Number of runs submitted" value={data.runCountTotal} isFetching={isFetching} />
    <StatisticItem title="Number of chronologies created" value={data.chronologyCountTotal} isFetching={isFetching} />
    <StatisticItem title="Number of events extracted" value={data.eventCountTotal} isFetching={isFetching} />
  </div>
);

export default StatisticsGrid;
