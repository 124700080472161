import React, { useState, useEffect, useRef } from 'react';

import { CHRONOS_TABS } from 'constants/chronosTabs';
import { useNavigate, useLocation } from 'react-router-dom';

interface NavigationTabsProps {
  caseId: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ caseId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = location.pathname.split('/').pop();
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsRef = useRef<HTMLDivElement>(null);

  const tabSelectedStyles = 'inline-flex justify-center items-center relative';
  const tabUnselectedStyles = 'inline-flex justify-center items-center';

  const handleTabClick = (e: React.MouseEvent, path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (tabsRef.current) {
      const currentTab = tabsRef.current.querySelector(`[data-tab="${currentView}"]`) as HTMLElement;
      if (currentTab) {
        setIndicatorStyle({
          left: currentTab.offsetLeft,
          width: currentTab.clientWidth,
        });
      }
    }
  }, [currentView]);

  return (
    <div className="w-full flex justify-between items-center">
      <div
        className="relative flex flex-row mb-3 border border-gray-300 border-x-0 border-t-0 border-b-1 w-fit"
        ref={tabsRef}
      >
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/summary?caseId=${caseId}`)}
          className={`relative cursor-pointer py-1 px-2 ${
            currentView === CHRONOS_TABS.SUMMARY ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.SUMMARY}
          data-testid="summary"
        >
          Home
        </div>
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/data/facts?caseId=${caseId}`)}
          className={`cursor-pointer py-1 px-4 ${
            currentView === CHRONOS_TABS.DATA ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.DATA}
          data-testid="data"
        >
          Data
        </div>

        <div
          onClick={() => navigate(`/app/chronos/case-editor/assistant?caseId=${caseId}`)}
          className={`cursor-pointer py-1 px-4 ${
            currentView === CHRONOS_TABS.ASSISTANT ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.ASSISTANT}
        >
          Kim
        </div>

        <div className="absolute bottom-0 h-0.5 bg-brandSecondary transition-all duration-300" style={indicatorStyle} />
      </div>
    </div>
  );
};

export default NavigationTabs;
