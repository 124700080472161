import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetSummary = (caseId: string | null = '') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getSummary = async () => {
    const response = await fetch(`${APIBaseChronos}/client/case/summary/${caseId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching messages failed');
    }
    const data = await response.json();
    const summaries = data?.summaries;

    if (!summaries || summaries.length === 0) {
      throw new Error('Summaries not found');
    }

    const formattedKeyFacts = [];
    for (let i = 0; i < data?.keyFacts.length; i++) {
      const fact = data?.keyFacts[i];
      formattedKeyFacts.push({
        ...fact,
        reference: data.eventIdReferences[fact.event_id],
      });
    }

    return {
      ...data,
      keyFacts: formattedKeyFacts,
    };
  };

  const { isFetching, isLoading, data, refetch, error } = useQuery(['summaryResult', caseId], getSummary, {
    enabled: false,
  });

  return {
    isFetching,
    isLoading,
    data,
    refetch,
    error,
  };
};

export default useGetSummary;
