import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface UpdateCaseParameters {
  caseId: string | null;
  updateSynopsisActive: boolean;
  synopsis: string;
}

const useUpdateCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const mutation = useMutation(({ caseId, updateSynopsisActive, synopsis }: UpdateCaseParameters) => {
    const fetchConfig = getFetchConfig({
      method: 'PUT',
      data: {
        isUpdateSynopsis: updateSynopsisActive,
        newSynopsis: updateSynopsisActive ? synopsis : undefined,
      },
    });
    return fetch(`${APIBaseChronos}/client/case/update/${caseId}`, fetchConfig)
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
        throw err; // Re-throw to allow react-query to catch and handle the error.
      });
  });

  return mutation;
};

export default useUpdateCase;
