import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery, UseQueryResult } from 'react-query';

function useFetchDocs(caseId: string | null): UseQueryResult<any, unknown> {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<any, unknown>(
    ['userDocs', caseId],
    () =>
      fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    {
      enabled: !!caseId, // Ensure caseId is not null before fetching
    },
  );
}

export default useFetchDocs;
