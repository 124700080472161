import React from 'react';

import cx from 'classnames';

interface PageWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const PageWrapper = ({ children, className }: PageWrapperProps) => {
  return (
    <div className={cx('flex flex-col w-full h-screen', className)}>
      <div className="flex flex-col md:flex-row items-start justify-start h-full overflow-y-auto">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-row w-full flex-wrap mt-10">
            <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
