import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetCaseOverview = (caseId: string | null = '') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getCaseOverview = async () => {
    const response = await fetch(`${APIBaseChronos}/client/case/overview/${caseId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching case overview failed');
    }
    const data = await response.json();
    return data;
  };

  const { isFetching, isLoading, data, refetch, error } = useQuery(['caseOverviewResult', caseId], getCaseOverview, {
    cacheTime: 0,
    enabled: false,
  });

  return {
    isFetching,
    isLoading,
    data,
    refetch,
    error,
  };
};

export default useGetCaseOverview;
