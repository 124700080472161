import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useFetchOrgMatters = (orgIds: string[] = []) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery(
    'fetchOrgMatters',
    () => {
      const queryParams = new URLSearchParams({ orgIds: orgIds.join(',') }).toString();
      return fetch(`${APIBaseChronos}/client/org/matters?${queryParams}`, {
        ...fetchConfigGET,
        method: 'GET',
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: true,
    },
  );
};

export default useFetchOrgMatters;
