import React from 'react';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, flexRender } from '@tanstack/react-table';
import { ChronosDoc, FilterOption } from 'types'; // Ensure these types are correctly imported based on your project's structure

import MultiselectFilter from '../components/Filters/MultiselectFilter'; // Adjust the import path as necessary

// Define the sort direction icons for convenience and readability
const sortingIcons: Record<string, JSX.Element> = {
  asc: (
    <FontAwesomeIcon
      icon={faArrowUp}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
  desc: (
    <FontAwesomeIcon
      icon={faArrowDown}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
};

interface HeaderContentProps {
  header: Header<ChronosDoc, unknown>;
  filterValue: FilterOption[];
}

export const HeaderContent: React.FC<HeaderContentProps> = ({ header, filterValue }) => {
  // Determine if the header should be centered based on its id
  const centerItem = ['facts_count', 'verified_facts_count', 'is_processed'].includes(header.id);

  return (
    <div
      className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-3 cursor-pointer`}
    >
      <div
        onClick={header.column.getToggleSortingHandler()}
        className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'}`}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortingIcons[header.column.getIsSorted() as string] ?? null}
      </div>
      {header.column.getCanFilter() && <MultiselectFilter column={header.column} filterValue={filterValue} />}
    </div>
  );
};
