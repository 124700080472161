import { ChangeEvent, forwardRef, useEffect, useRef } from 'react';

interface TextAreaWithLabelProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
}

const TextAreaWithLabel = forwardRef<HTMLTextAreaElement, TextAreaWithLabelProps>(({ value, onChange, label }, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaId = `textarea-${label.replace(/\s+/g, '-').toLowerCase()}`; // Generate a unique ID based on the label text
  const autoResize = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = '56px'; // Reset the height
      if (textArea.scrollHeight > 56) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    }
  };

  // Adjust the height initially and whenever the value changes
  useEffect(() => {
    autoResize();
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
    autoResize();
  };

  return (
    <div className="relative mt-4 flex items-center">
      <label
        htmlFor={textAreaId}
        className={`absolute left-3 top-5 transition-all duration-300 ${
          value ? 'text-xs transform -translate-y-4' : 'text-base'
        }`}
        style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
      >
        {label}
      </label>
      <textarea
        id={textAreaId}
        ref={textAreaRef}
        className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-lg bg-white resize-none"
        style={{
          backgroundColor: 'rgb(238, 238, 238, 0.5)',
          overflow: 'hidden',
          outline: 'none',
          lineHeight: '26px',
          height: '56px', // Initial height
          paddingTop: '20px',
          paddingBottom: '20px',
          verticalAlign: 'middle',
          zIndex: 2,
          border: '1px solid var(--black-10, #E7E7E7)',
        }}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
});

TextAreaWithLabel.displayName = 'TextAreaWithLabel';

export default TextAreaWithLabel;
