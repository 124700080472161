import { Viewer } from '@react-pdf-viewer/core';
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface PDFViewerProps {
  fileUrl: string;
  initialPage: number;
  highlights: HighlightArea[];
}

const PDFViewer = ({ fileUrl, initialPage, highlights = [] }: PDFViewerProps) => {
  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlights
          .filter((area) => {
            return area.pageIndex === props.pageIndex;
          })
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  background: '#FFFF00',
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation),
              )}
            />
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPage } =
    pageNavigationPluginInstance;

  return (
    <>
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(90vh - 50px)',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            padding: '4px',
          }}
        >
          <div style={{ padding: '0px 2px' }}>
            <GoToFirstPageButton />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <GoToPreviousPage />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <CurrentPageInput />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <GoToNextPageButton />
          </div>
          <div style={{ padding: '0px 2px' }}>
            <GoToLastPageButton />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer
            fileUrl={fileUrl}
            plugins={[pageNavigationPluginInstance, highlightPluginInstance]}
            initialPage={initialPage - 1} // package 0 indexes pages
          />{' '}
        </div>
      </div>
    </>
  );
};

export default PDFViewer;
