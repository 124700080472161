import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface HookProps {
  onSuccess: (data: any) => void;
  onError: (data: ApiError) => void;
}
export interface ApiError {
  statusCode: number;
  message: string;
}

const useVerifyChronologyEvent = ({ onSuccess, onError }: HookProps) => {
  const { getFetchConfig } = useGetFetchConfig();
  const updateVerifiedMutation = useMutation(
    ({ line_id, verified }: { line_id: string; verified: boolean }) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          verified: verified,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/chronology/${line_id}`, fetchConfig).then((res) => res.json());
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    },
  );

  return {
    updateVerifiedMutation,
  };
};

export default useVerifyChronologyEvent;
