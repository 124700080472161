import { useMemo, useState } from 'react';

import { PaginationState } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

export const useTablePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  // Initialize state with query parameters or default values
  const [pagination, setInternalPagination] = useState<PaginationState>({
    pageIndex: page ? Number(page) - 1 : 0, // Adjusting to 0-indexed internally
    pageSize: 100, // Default page size
  });

  // Function to update pagination and sync with URL
  const setPagination = (newPaginationState: PaginationState) => {
    setInternalPagination(newPaginationState);
    // Updating URL search params
    searchParams.set('page', String(newPaginationState.pageIndex + 1)); // Converting back to 1-indexed for URL
    setSearchParams(searchParams);
  };

  // useMemo to avoid unnecessary recalculations
  const paginationMemo = useMemo(
    () => ({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    }),
    [pagination.pageIndex, pagination.pageSize],
  );

  // Returning pagination object, setPagination function, and pageIndex, pageSize directly for convenience
  return {
    pagination: paginationMemo,
    setPagination,
    pageIndex: paginationMemo.pageIndex,
    pageSize: paginationMemo.pageSize,
  };
};
