const DataViewToolbar = ({
  titleSection,
  longTitleSection,
  actionButtons,
  searchBar,
  filterButtons,
  pagination,
}: {
  titleSection: React.ReactNode;
  longTitleSection?: boolean;
  actionButtons?: React.ReactNode;
  searchBar?: React.ReactNode;
  filterButtons?: React.ReactNode;
  pagination: React.ReactNode;
}) => {
  const Divider = () => <div className="w-1 h-8 border-r flex items-center justify-center"></div>;

  return (
    <div className="flex-shrink-0 h-[68px] mb-1 border-b-2 border-opacity-20 border-gray-600 pb-1">
      <div className="flex justify-between pt-3 h-full">
        <div className="flex gap-1 items-center">
          {/* Title Section */}
          <div className={`${longTitleSection ? 'w-fit' : 'w-36'} border-gray-600`}>{titleSection}</div>

          <Divider />

          {/* Action Buttons */}
          {actionButtons ? (
            <>
              <div className="flex gap-4 items-center justify-between px-4 w-fit">{actionButtons}</div>
              <Divider />
            </>
          ) : null}
        </div>

        <div className="flex items-center gap-2">
          {/* Filters */}
          {filterButtons ? (
            <>
              <div className="flex items-center gap-2 px-2">{filterButtons}</div>
              <Divider />
            </>
          ) : null}

          {/* Search Bar */}
          {searchBar}
          <Divider />

          {/* Pagination */}
          <div className="w-32 mx-1">{pagination}</div>
        </div>
      </div>
    </div>
  );
};

export default DataViewToolbar;
