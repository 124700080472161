import { useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const useDocumentNavigation = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const goToDocReference = useCallback(
    (newDocId: string, newEventId: string, pageNumber: number) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('docId', newDocId);
      searchParams.set('eventId', newEventId);
      searchParams.set('pageNumber', pageNumber.toString());
      navigate(`${pathname}?${searchParams}`, { replace: true });
    },
    [navigate, pathname, search],
  ); // Dependencies

  return { goToDocReference };
};
