import React from 'react';

import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';

interface ChartCardProps {
  title: string;
  tooltipContent: string;
  children: React.ReactNode;
}

const ChartCard = ({ title, tooltipContent, children }: ChartCardProps) => {
  return (
    <div className="bg-white p-8 rounded-xl">
      <div className="flex justify-between items-baseline">
        <div className="flex flex-row items-center font-semibold gap-1">
          {title}
          <ReactInfo
            data-tooltip-id={`${title}-tooltip`}
            data-tooltip-content={tooltipContent}
            className="mr-2 text-sm cursor-pointer text-slate-400"
          />
          <Tooltip id={`${title}-tooltip`} style={TooltipStyles} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default ChartCard;
