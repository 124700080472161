import { useCallback, useEffect, useRef, useState } from 'react';

import { faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';

import useGetFetchConfig from '../../../../api/useGetFetchConfig';
import PDFViewer from '../../PDFViewer';
import FactsEditor from '../FactsEditor';
import FactAdder from '../FactsEditor/FactAdder';

const documentWidth = 750;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    height: '90vh',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const DocViewer = ({ caseId, docId, eventId, pageNumber }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewerRef = useRef(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isDocumentView, setIsDocumentView] = useState(false);
  const [minPage, setMinPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [documents, setDocuments] = useState([]);
  const [highlightCoordinates, setHighlightCoordinates] = useState([]);
  const isFactsOrChronologiesPage = location.pathname.includes('/facts') || location.pathname.includes('/chronology');
  const { fetchConfigGET } = useGetFetchConfig();

  // Assuming fetchConfigGET and other dependencies are available in the component's scope
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/client/case/doc/download/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET]); // Add all dependencies here

  const { refetch: fetchDocument } = useQuery(
    { queryKey: ['downloadDoc', docId], queryFn: getDocumentUrl },
    { enabled: false },
  );

  const getResponseDocs = () => {
    if (caseId) {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseDocs } = useQuery({ queryKey: ['userDocs'], queryFn: getResponseDocs });

  const getRefCoords = () => {
    if (eventId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinates/${eventId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    } else if (docId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinatesByDocId/${docId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseCoordinates } = useQuery({
    queryKey: ['referenceCoordinates', docId, eventId],
    queryFn: getRefCoords,
  });

  const handleCloseModal = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('caseId', caseId);
    searchParams.delete('docId');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [caseId, navigate, location.pathname, location.search]);

  const filename = responseDocs?.docs?.find((doc) => doc.doc_id === docId)?.file_name || '';
  const fileDescription = responseDocs?.docs?.find((doc) => doc.doc_id === docId)?.file_description || '';

  // const goToDoc = useCallback(
  //   ({ newDocIndex }) => {
  //     setFileUrl('');
  //     const document = documents[newDocIndex];
  //     const searchParams = new URLSearchParams(location.search);
  //     searchParams.set('docId', document.docId);
  //     searchParams.set('pageNumber', document.initialPage);
  //     navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  //   },
  //   [documents, navigate, location.pathname, location.search],
  // );

  const handleCloseDocDetailView = useCallback(() => {
    setIsDocumentView(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', docId);
    searchParams.delete('eventId');
    searchParams.delete('pageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [docId, navigate, location.pathname, location.search]);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    handleCloseDocDetailView();
  }, [handleCloseDocDetailView]);

  const handleNewFactCreated = useCallback(() => {
    setModalIsOpen(false);
    // Assuming currentPathWithQuery remains the same or is derived from state/props that are dependencies
    const currentPathWithQuery = window.location.pathname + window.location.search;
    navigate(currentPathWithQuery, { replace: true, state: { key: Date.now() } });
  }, [navigate]); // Add any dependencies if they are used to derive currentPathWithQuery

  useEffect(() => {
    if (docId) {
      fetchDocument().then(({ data: documentUrl }) => {
        setFileUrl(documentUrl);
      });
    }
    // eslint-disable-next-line
  }, [docId]);

  useEffect(() => {
    if (responseDocs && !eventId) {
      // setDocuments(
      //   responseDocs.docs.map((doc) => {
      //     return { docId: doc.doc_id, initialPage: 0 };
      //   }),
      // );
    }
  }, [responseDocs, eventId]);

  useEffect(() => {
    if (docId && responseCoordinates) {
      // const docIdsRelatedToEvent = [
      //   ...new Set(
      //     responseCoordinates?.factsCoordinates.map((coordinate) => {
      //       return {
      //         docId: coordinate.doc_id,
      //         initialPage: coordinate.n_page,
      //       };
      //     }),
      //   ),
      // ];

      setMinPage(responseCoordinates.minPage);

      // setDocuments(docIdsRelatedToEvent);
      setHighlightCoordinates(
        responseCoordinates?.factsCoordinates
          ?.filter((coordinate) => coordinate.doc_id === docId)
          ?.map((coordinate) => coordinate.coordinate_details)
          ?.flat()
          ?.map((coordinate) => {
            return {
              height: coordinate.height_c * 100,
              left: coordinate.left_c * 100,
              pageIndex: coordinate.n_page - 1,
              top: coordinate.top_c * 100,
              width: coordinate.width_c * 100,
            };
          }) || [],
      );
    }
  }, [responseCoordinates, docId, eventId]);

  useEffect(() => {
    if (eventId) {
      setIsDocumentView(true);
    }
  }, [eventId]);

  // const currentDocIndex = documents.map((x) => x.docId).indexOf(docId);
  // const isPrevPage = currentDocIndex > 0;
  // const isNextPage = currentDocIndex < documents.length - 1;

  const hasContentFilter = responseDocs?.docs?.length
    ? responseDocs.docs.find((doc) => doc.doc_id === docId)?.content_filter
    : false;
  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <FactAdder docs={responseDocs?.docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
      </Modal>
      <div
        className=""
        style={{
          width: isDocumentView ? '750px' : '90vw',
          height: '90vh',
          position: 'relative',
        }}
      >
        <div className="flex flex-row w-full justify-between items-center" style={{ height: '50px' }}>
          <FontAwesomeIcon
            icon={isDocumentView && !isFactsOrChronologiesPage ? faChevronLeft : faClose}
            className="w-7 h-7 cursor-pointer"
            color={'black'}
            onClick={() =>
              isDocumentView && !isFactsOrChronologiesPage ? handleCloseDocDetailView() : handleCloseModal()
            }
          />
        </div>
        {!isDocumentView && (
          <>
            <div className="text-black text-3xl not-italic font-bold mb-2 mt-5">{filename}</div>
            <div className="text-gray-700 text-base not-italic font-medium leading-6 mb-6" style={{ opacity: 0.8 }}>
              {fileDescription}
            </div>
          </>
        )}
        {fileUrl ? (
          fileUrl === 'download-error' ? (
            'File cannot be displayed, please try again later.'
          ) : isDocumentView ? (
            <div
              ref={viewerRef}
              className="mx-auto"
              style={{ width: documentWidth, height: '100%', position: 'relative' }}
            >
              <PDFViewer fileUrl={fileUrl} initialPage={pageNumber || minPage} highlights={highlightCoordinates} />
            </div>
          ) : (
            <div className="flex flex-row items-end mb-7" style={{ height: '162px' }}>
              <div
                style={{
                  width: '304px',
                  height: '162px',
                  overflowY: 'hidden',
                  borderRadius: '16px',
                  position: 'relative',
                }}
              >
                <PDFViewer fileUrl={fileUrl} />
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: '0',
                    top: '0',
                    background: 'rgba(0,0,0,0.5)',
                  }}
                ></div>
              </div>
              <div
                className="flex justify-center items-center px-2 py-3 rounded-lg  not-italic font-bold  w-56 h-12 cursor-pointer ml-4"
                style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                onClick={() => setIsDocumentView(true)}
              >
                View unredacted
              </div>
            </div>
          )
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> Your document is being loaded
          </div>
        )}
        {!eventId && hasContentFilter && !isDocumentView && (
          <div className="w-full h-24 flex items-center justify-center">
            <p>
              Document did not pass content filter, possible illicit content forbidden by model provider. Please
              manually add facts.
            </p>
          </div>
        )}
        {!hasContentFilter && !isDocumentView && <FactsEditor docId={docId} onlyTable={true} />}
      </div>
    </>
  );
};

export default DocViewer;
