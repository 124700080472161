import React, { useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { IMPORTANCY_OPTIONS, CLASSIFICATION_OPTIONS } from 'constants/chronos_table_options';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosDoc, MyOptionType } from 'types';
interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  docId?: string;
}
const FactAdder = ({ handleNewFactCreated, docs, docId }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [selectedImportancy, setSelectedImportancy] = useState<MyOptionType>(null);
  const [selectedClassification, setSelectedClassification] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const [subjectMatter, setSubjectMatter] = useState<string>('');
  const [actionDescribed, setActionDescribed] = useState<string>('');
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');
  const [dateOfDocument, setDateOfDocument] = useState<string>('');

  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingAddNewFact, refetch: refetchAddNewFact } = useQuery(
    ['addFactQuery', selectedDoc?.value],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          subject_matter: subjectMatter,
          action_described: actionDescribed,
          date_of_subject_text: dateOfSubjectMatterText,
          document_date_text: dateOfDocument,
          important: selectedImportancy?.value,
          classification: selectedClassification?.value,
          relevance_reason: significance,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/fact/${docId || selectedDoc?.value}`, fetchConfig)
        .then((res) => {
          Swal.fire({
            title: 'New fact created succesfully',
            text: 'A new fact was created succesfully',
            showConfirmButton: false,
            timer: 2500,
          });
          handleNewFactCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while adding new fact',
            text: 'There was an error while adding new fact. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeSubjectMatter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubjectMatter(e.target.value);
  };

  const handleChangeActionDescribed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActionDescribed(e.target.value);
  };

  const handleAddFact = () => {
    refetchAddNewFact();
  };

  const handleChangeSelectedDoc = (val: MyOptionType) => {
    setSelectedDoc(val);
  };

  const handleChangeSelectImportancy = (val: MyOptionType) => {
    setSelectedImportancy(val);
  };
  const handleChangeSelectClassification = (val: MyOptionType) => {
    setSelectedClassification(val);
  };

  const handleChangeDateOfSubjectMatterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  };

  const handleChangeSignificance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  };

  const handleChangeDateOfDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfDocument(e.target.value);
  };

  const docsOptions = docs.map((doc) => {
    return { value: doc.doc_id, label: doc.file_name };
  });

  const disabled = !selectedDoc && !docId;
  return (
    <div style={{ width: '350px', maxHeight: '70vh', padding: '30px' }}>
      <div className="h-12 pl-3 w-full focus:outline-none text-black text-3xl not-italic font-bold placeholder:text-gray-400 rounded-lg bg-white mt-4">
        New Fact
      </div>
      <input
        onChange={handleChangeSubjectMatter}
        className="h-12 pl-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={subjectMatter}
        placeholder="Subject matter"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      {/* <input
        onChange={handleChangePersons}
        className="h-12 pl-3 w-full mt-2 focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={persons}
        style={{ border: '1px solid #ECEFFF' }}
        placeholder="Persons"
      ></input> */}
      <input
        onChange={handleChangeActionDescribed}
        className="h-12 pl-3 mt-2 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={actionDescribed}
        placeholder="Action described"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      {!docId && (
        <Select
          options={docsOptions}
          className="outline-none w-full bg-white rounded-md mt-3"
          styles={reactSelectStylesFormFact}
          onChange={handleChangeSelectedDoc}
          value={selectedDoc}
          placeholder={'Select a document'}
        />
      )}
      <input
        onChange={handleChangeDateOfSubjectMatterText}
        className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={dateOfSubjectMatterText}
        placeholder="Date of subject matter"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      {!docId && (
        <input
          onChange={handleChangeDateOfDocument}
          className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
          value={dateOfDocument}
          placeholder="Date of document"
          style={{ border: '1px solid #ECEFFF' }}
        ></input>
      )}
      <input
        onChange={handleChangeSignificance}
        className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
        value={significance}
        placeholder="Significance"
        style={{ border: '1px solid #ECEFFF' }}
      ></input>
      <Select
        options={Object.values(IMPORTANCY_OPTIONS)}
        className="outline-none w-full bg-white rounded-md mt-3"
        styles={reactSelectStylesFormFact}
        onChange={handleChangeSelectImportancy}
        value={selectedImportancy}
        placeholder={'Important'}
      />
      <Select
        options={Object.values(CLASSIFICATION_OPTIONS)}
        className="outline-none w-full bg-white rounded-md mt-3"
        styles={reactSelectStylesFormFact}
        onChange={handleChangeSelectClassification}
        value={selectedClassification}
        placeholder={'Classification'}
      />
      <button
        disabled={disabled}
        onClick={() => {
          !disabled && handleAddFact();
        }}
        className="flex justify-center items-center px-2 py-3 rounded-lg mt-3   not-italic font-bold w-56 cursor-pointer ml-auto disabled:bg-gray-500 disabled:opacity-20 disabled:cursor-not-allowed mb-6"
        style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
      >
        {isLoadingAddNewFact ? <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> : 'Add'}
      </button>
    </div>
  );
};

export default FactAdder;
