import { ReactNode, useEffect, useState } from 'react';

import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';

type Size = 'small' | 'large';

export default function SettingsModal({
  title,
  content,
  isOpen,
  handleClose,
  size,
}: {
  title: string | ReactNode;
  content: ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  size?: Size;
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) setOpen(true);
    if (!isOpen) setOpen(false);
  }, [isOpen]);

  const closeModal = () => {
    if (handleClose) handleClose();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`relative transform rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 ${
              size === 'small' ? 'sm:max-w-lg w-[480px]' : 'sm:max-w-4xl sm:w-full'
            } sm:max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
          >
            <div className="px-6 py-4 flex justify-between text-lg border border-x-0 border-t-0">
              <div>{title}</div>
              <button onClick={closeModal} className="hover:text-blue-500 transition-colors duration-300">
                <FontAwesomeIcon icon={faX} className="w-4 h-4" />
              </button>
            </div>
            {content}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
