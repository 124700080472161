import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig'; // Ensure this path is correct
import { useMutation } from 'react-query';

interface UserCreateThreadProps {
  caseId: string;
  question: string;
}

interface UserCreateMessageProps {
  question: string;
}

const useCreateThreadAndMessage = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const createMessage = async ({ question, caseId }: UserCreateMessageProps & { caseId: string }) => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        question,
      },
    });

    const response = await fetch(`${APIBaseChronos}/client/case/assistant/question/${caseId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error creating message');
    }
    return response.json();
  };

  const mutation = useMutation(async ({ question, caseId }: UserCreateMessageProps & UserCreateThreadProps) => {
    return createMessage({ question, caseId });
  });

  return mutation;
};

export default useCreateThreadAndMessage;
