const AGREED_FILTER_OPTIONS = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'Potentially not agreed',
    label: 'Potentially not agreed',
  },
  {
    value: 'No',
    label: 'No',
  },
];

const IMPORTANT_FILTER_OPTIONS = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
  {
    value: 'May be relevant',
    label: 'May be relevant',
  },
];

const PROCESSED_FILTER_OPTIONS = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const VERIFIED_FILTER_OPTIONS = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const CLASSIFICATION_FILTER_OPTIONS = [
  {
    value: 'primary',
    label: 'Primary',
  },
  {
    value: 'secondary',
    label: 'Secondary',
  },
  {
    value: 'future',
    label: 'Future',
  },
  {
    value: 'litigation',
    label: 'Litigation',
  },
];

export const PlaceholderLabel: Record<string, string> = {
  agreed: 'agreed',
  important: 'important',
  is_processed: 'processed',
  verified: 'verified',
  classification: 'classification',
};

export const FILTER_OPTIONS = {
  important: IMPORTANT_FILTER_OPTIONS,
  agreed: AGREED_FILTER_OPTIONS,
  is_processed: PROCESSED_FILTER_OPTIONS,
  verified: VERIFIED_FILTER_OPTIONS,
  classification: CLASSIFICATION_FILTER_OPTIONS,
};
