import { useCallback, useEffect, useRef, useState } from 'react';

import { faChartSimple, faGrip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@react-pdf-viewer/core';
import ChartCard from 'components/molecules/ChartCard';
import AreaChart from 'components/molecules/Charts/AreaChart';
import PageWrapper from 'components/molecules/PageWrapper';
import StatisticsGrid from 'components/molecules/StatisticsGrid';
import StatisticsTable from 'components/molecules/Tables/StatisticsTable';
import Filters from 'components/molecules/UsageFilters';
import { useUserContext } from 'Contexts/User';
import useFetchOrganisations from 'hooks/useFetchOrganisations';
import useFetchOrgData from 'hooks/useFetchOrgData';
import useFetchOrgMatters from 'hooks/useFetchOrgMatters';
import useFetchOrgUsers from 'hooks/useFetchOrgUsers';
import { ActionMeta, MultiValue } from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { MatterResponse, MyOptionType, UserResponse } from 'types';
import * as XLSX from 'xlsx';

import SearchBox from '../CaseEditor/components/SearchBox';

const UsageDashboard = () => {
  const { user } = useUserContext();
  const wexlerAdmin = user?.metadata?.wexlerAnalyticsAdmin === 'true';
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // Calculate default start date (1 month ago) and end date (now)
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  const defaultEndDate = new Date();

  // Page view
  const [view, setView] = useState<'table' | 'charts'>('charts');

  // Filters with default start and end dates
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [selectedUsers, setSelectedUsers] = useState<MultiValue<MyOptionType>>([]);
  const [selectedMatters, setSelectedMatters] = useState<MultiValue<MyOptionType>>([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<MultiValue<MyOptionType>>([]);

  // Table search
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tableGrouping, setTableGrouping] = useState<string>('matter');

  // Ensure selectedMatters is not null or undefined
  const matterIds = selectedMatters.filter((matter) => matter !== null).map((matter) => matter!.value);
  const userIds = selectedUsers.filter((user) => user !== null).map((user) => user!.value);
  const orgIds = selectedOrganisations.filter((org) => org !== null).map((org) => org!.value);

  // Fetch users and matters in the organization
  const { data: usersData, isFetching: isFetchingUsers, refetch: refetchUsers } = useFetchOrgUsers(orgIds);
  const { data: mattersData, isFetching: isFetchingMatters, refetch: refetchMatters } = useFetchOrgMatters(orgIds);

  // Fetch organisations for Wexler admins
  const { data: orgData, isFetching: isFetchingOrgData } = useFetchOrganisations(wexlerAdmin);

  // State to track when user, matter and org data is loaded
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isFetchingUsers && !isFetchingMatters && !isFetchingOrgData) {
      setIsDataLoaded(true);
    }
  }, [isFetchingUsers, isFetchingMatters, isFetchingOrgData]);

  useEffect(() => {
    if (selectedOrganisations.length > 0) {
      refetchUsers();
      refetchMatters();
    }
  }, [selectedOrganisations, refetchUsers, refetchMatters]);

  // Fetch all the usage data
  const {
    isFetching,
    data: responseData,
    refetch,
  } = useFetchOrgData(
    isDataLoaded,
    startDate ? startDate.toISOString() : undefined,
    endDate ? endDate.toISOString() : undefined,
    matterIds,
    userIds,
    usersData?.users,
    orgIds,
  );

  useEffect(() => {
    if (isDataLoaded) {
      refetch();
    }
  }, [startDate, endDate, selectedUsers, selectedMatters, selectedOrganisations, refetch, isDataLoaded]);

  const userOptions =
    usersData?.users.map((user: UserResponse) => ({
      label: user.email,
      value: user.userId,
    })) || [];

  const matterOptions =
    mattersData?.items?.map((matter: MatterResponse) => ({
      label: selectedOrganisations.length > 0 ? matter.matter_id : matter.latest_matter_name,
      value: matter.matter_id,
    })) || [];

  const organisationOptions =
    orgData?.orgs?.map((org) => ({
      label: org.name,
      value: org.id,
    })) || [];

  const handleChangeSelectedUsers = useCallback(
    (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setSelectedUsers(newValue);
    },
    [],
  );

  const handleChangeSelectedMatters = useCallback(
    (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setSelectedMatters(newValue);
    },
    [],
  );

  const handleChangeSelectedOrganisations = useCallback(
    (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setSelectedOrganisations(newValue);
    },
    [],
  );

  const onSearchCall = () => {
    setSearchFilter(searchText);
  };

  const toggleTableGrouping = (value: string) => {
    setTableGrouping(value);
  };

  const handleDownloadClick = useCallback(() => {
    if (responseData) {
      const startDateString = startDate?.toISOString().split('T')[0];
      const endDateString = endDate?.toISOString().split('T')[0];
      const fileName = `usage_data_${startDateString}_to_${endDateString}.xlsx`;

      // Initialize a new workbook
      const workbook = XLSX.utils.book_new();

      // Add Stats by Matter sheet
      const statsByMatter = responseData?.statsByMatter || [];
      const worksheetMatter = XLSX.utils.json_to_sheet(statsByMatter);
      XLSX.utils.book_append_sheet(workbook, worksheetMatter, 'Stats by Matter');

      // Add Stats by User sheet
      const statsByUser = responseData?.statsByUser || [];
      const worksheetUser = XLSX.utils.json_to_sheet(statsByUser);
      XLSX.utils.book_append_sheet(workbook, worksheetUser, 'Stats by User');

      // Add Page Count Breakdown sheet
      const pageCountBreakdown = responseData?.pageCountWeeklyBreakdown || [];
      const worksheetPages = XLSX.utils.json_to_sheet(pageCountBreakdown);
      XLSX.utils.book_append_sheet(workbook, worksheetPages, 'Page Count Breakdown');

      // Add Run Count Breakdown sheet
      const runCountBreakdown = responseData?.runCountWeeklyBreakdown || [];
      const worksheetRuns = XLSX.utils.json_to_sheet(runCountBreakdown);
      XLSX.utils.book_append_sheet(workbook, worksheetRuns, 'Run Count Breakdown');

      // Add Chronology Count Breakdown sheet
      const chronologyCountBreakdown = responseData?.chronologyCountWeeklyBreakdown || [];
      const worksheetChronologies = XLSX.utils.json_to_sheet(chronologyCountBreakdown);
      XLSX.utils.book_append_sheet(workbook, worksheetChronologies, 'Chronology Count Breakdown');

      // Write the workbook to a file
      XLSX.writeFile(workbook, fileName);
    }
  }, [responseData, startDate, endDate]);

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">
        <div className="mb-8 w-full pr-10 pl-3 flex flex-col gap-6">
          <div className="flex justify-between items-start">
            <div className="text-gray-700 flex justify-center items-center text-sm mr-auto">
              <button
                onClick={() => setView('charts')}
                className={
                  `flex gap-1 items-center justify-center border py-2 px-4 rounded-tl-lg ` +
                  `rounded-bl-lg hover:border-blue-500 hover:text-blue-500 ` +
                  `${classNames({ 'border-blue-500 text-blue-500': view === 'charts' })}`
                }
              >
                <FontAwesomeIcon icon={faGrip} />
                Charts
              </button>
              <button
                onClick={() => setView('table')}
                className={
                  `flex gap-1 items-center justify-center border py-2 px-4 rounded-tr-lg ` +
                  `rounded-br-lg hover:border-blue-500 hover:text-blue-500 ` +
                  `${classNames({ 'border-blue-500 text-blue-500': view === 'table' })}`
                }
              >
                <FontAwesomeIcon icon={faChartSimple} />
                Table
              </button>
            </div>

            <Filters
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={setStartDate}
              onChangeEndDate={setEndDate}
              userOptions={userOptions}
              selectedUsers={selectedUsers}
              onChangeSelectedUsers={handleChangeSelectedUsers}
              matterOptions={matterOptions}
              selectedMatters={selectedMatters}
              onChangeSelectedMatters={handleChangeSelectedMatters}
              organisationOptions={wexlerAdmin ? organisationOptions : undefined}
              selectedOrganisations={wexlerAdmin ? selectedOrganisations : undefined}
              onChangeSelectedOrganisations={wexlerAdmin ? handleChangeSelectedOrganisations : undefined}
            />
            <div className="relative ml-2" ref={wrapperRef}>
              <div
                className={`flex justify-center items-center px-2 py-2 rounded-lg font-bold w-36 cursor-pointer ${
                  isFetching ? 'cursor-not-allowed' : ''
                }`}
                style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                onClick={!isFetching ? handleDownloadClick : undefined}
                data-tooltip-id="download-tooltip"
              >
                {isFetching ? <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> : 'Download'}
              </div>
            </div>
          </div>

          {view === 'table' ? (
            <div className="flex flex-col justify-center w-full gap-8">
              <div className="w-full flex flex-row justify-end">
                <div className="w-96">
                  <SearchBox
                    value={searchText}
                    onChange={setSearchText}
                    onSearchCall={onSearchCall}
                    placeholder="Search"
                  />
                </div>
              </div>

              <div className="w-full rounded-xl border border-gray-200 text-sm">
                <StatisticsTable
                  searchFilter={searchFilter}
                  columns={[
                    tableGrouping === 'matter'
                      ? { header: 'Matter', accessorKey: selectedOrganisations.length > 0 ? 'matterId' : 'matterName' }
                      : { header: 'User', accessorKey: 'userEmail' },
                    { header: 'Number of pages', accessorKey: 'pages' },
                    { header: 'Number of runs', accessorKey: 'runs' },
                    { header: 'Number of events', accessorKey: 'events' },
                    { header: 'Number of chronologies', accessorKey: 'chronologies' },
                  ]}
                  rows={
                    tableGrouping === 'matter'
                      ? responseData?.statsByMatter || []
                      : tableGrouping === 'user'
                      ? responseData?.statsByUser || []
                      : []
                  }
                  toggleGroupBy={toggleTableGrouping}
                  chosenOption={{
                    label: tableGrouping.charAt(0).toUpperCase() + tableGrouping.slice(1),
                    value: tableGrouping,
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <StatisticsGrid
                isFetching={isFetching}
                data={
                  responseData
                    ? {
                        pageCountTotal: responseData.pageCountTotal,
                        runCountTotal: responseData.runCountTotal,
                        chronologyCountTotal: responseData.chronologyCountTotal,
                        eventCountTotal: responseData.eventCountTotal,
                      }
                    : { pageCountTotal: 0, runCountTotal: 0, chronologyCountTotal: 0, eventCountTotal: 0 }
                }
              />
              <ChartCard title="Pages Processed" tooltipContent="Number of Pages Processed">
                <AreaChart
                  className="h-80"
                  data={responseData?.pageCountWeeklyBreakdown || []}
                  index="week"
                  categories={['pages']}
                  valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                />
              </ChartCard>
              <ChartCard title="Number of runs" tooltipContent="Number of Runs">
                <AreaChart
                  className="h-80"
                  data={responseData?.runCountWeeklyBreakdown || []}
                  index="week"
                  categories={['runs']}
                  valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                />
              </ChartCard>
              <ChartCard title="Number of chronologies" tooltipContent="Number of Chronologies">
                <AreaChart
                  className="h-80"
                  data={responseData?.chronologyCountWeeklyBreakdown || []}
                  index="week"
                  categories={['chronologies']}
                  valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                />
              </ChartCard>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default UsageDashboard;
