import BookAnimation from 'assets/animations/Book.json';
import DocAnimation from 'assets/animations/Scan.json';
import LottieAnimation from 'components/atoms/Lottie';

export const SummaryTooltip = () => {
  return (
    <div>
      <div className="h-24 bg-gray-100 flex justify-center items-center">
        <LottieAnimation animationData={BookAnimation} height={190} width={190} />
      </div>
      <div className="px-8 pb-10">
        <p className="font-semibold text-lg pb-2 pt-4">Summaries</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>
            {' '}
            Wexler uses intelligent AI to analyse the extracted facts and create an executive overview of the case,
            focusing on the key facts how how they relate to the original synopsis.
          </p>

          <p className="text-sm text-gray-500 border border-x-0 border-b-0 pt-4">
            This summary is directional and should not be considered comprehensive or exhaustive.
          </p>
        </div>
      </div>
    </div>
  );
};

export const ThemeTooltip = () => {
  return (
    <div>
      <div className="h-24 bg-gray-100 flex justify-center items-center">
        <LottieAnimation animationData={DocAnimation} height={150} width={150} />
      </div>
      <div className="px-8 pb-10">
        <p className="font-semibold text-lg pb-2 pt-4">Themes</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>
            {' '}
            Themes relate to the overarching issues highlighted in a case. Wexler's AI uses both the case synopsis and
            the extracted facts to pull out topics and potential legal issues across the whole document set.
          </p>
          <p>Each theme contains a theme overview as well as further subquestion questions related to it.</p>

          <p className="text-sm text-gray-500 border border-x-0 border-b-0 pt-4">
            The extracted themes are directional and should not be considered comprehensive or exhaustive.
          </p>
        </div>
      </div>
    </div>
  );
};
