import React from 'react';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface QuestionBoxProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  onExecuteCall?: () => void;
}

const QuestionBox: React.FC<QuestionBoxProps> = ({ value, onChange, placeholder, onExecuteCall }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onExecuteCall && onExecuteCall();
    }
  };
  return (
    <div className="relative flex flex-col gap-4 items-center w-[36rem]">
      <div className="z-10 border-2 rounded-3xl flex gap-2 py-1 px-2 w-full">
        <input
          className="bg-white text-gray-800 px-2 w-full focus:outline-none not-italic font-normal text-base placeholder:text-gray-400"
          onChange={(e) => onChange && onChange(e.target.value)}
          value={value}
          placeholder={placeholder || 'Ask Kim..'}
          onKeyDown={onKeyDown}
        />
        <button
          className="cursor-pointer rounded-full flex justify-center items-center py-2 px-[9px] bg-brandSecondary hover:bg-brandSecondary-hover disabled:bg-gray-500 font-semibold text-sm text-white"
          onClick={onExecuteCall}
        >
          <FontAwesomeIcon icon={faArrowRight} className="text-white" />
        </button>
      </div>
    </div>
  );
};

export default React.memo(QuestionBox);
