import { ReactNode, useEffect, useState } from 'react';

import {
  fa1,
  fa2,
  faArrowRight,
  faBook,
  faCircleCheck,
  faCircleInfo,
  faCircleNotch,
  faFile,
  faRefresh,
  faUpload,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/molecules/Modals/Settings';
import FileUploaderComponent from 'components/organisms/DocumentAdder';
import useFetchCaseObject from 'PrivateApp/Chronos/CaseEditor/hooks/useFetchCaseObject';
import useUpdateCase from 'PrivateApp/Chronos/CaseEditor/hooks/useUpdateCaseObject';
import { StageSpinner } from 'react-spinners-kit';

const Synopsis = ({
  caseName,
  caseSynopsis,
  isFetching,
  handleSynopsisChange,
  setActiveTab,
}: {
  caseName: string;
  caseSynopsis: string;
  isFetching: boolean;
  handleSynopsisChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setActiveTab: (tab: string) => void;
}) => {
  return (
    <div className="flex flex-col h-full justify-between">
      <p className="font-semibold text-xl mb-2">{caseName}</p>
      <div>
        {isFetching ? (
          <div className="h-72 flex justify-center items-center">
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          </div>
        ) : (
          <textarea
            className="border px-4 py-2 h-72 transition-all focus:border-blue-300 duration-300 hover:bg-gray-50 w-full focus:outline-none not-italic font-normal text-sm rounded resize-none"
            value={caseSynopsis}
            onChange={handleSynopsisChange}
          />
        )}
      </div>
      <p className="text-gray-700 mb-10 text-xs">
        Click through to <b>Add Documents</b> to add any additional documents and update your case.
      </p>

      <div className="flex justify-between">
        <div className="">
          <button
            onClick={() => setActiveTab('Documents')}
            className="bg-buttonPrimary shadow text-white hover:bg-buttonPrimary-hover flex gap-2 items-center justify-center py-1 px-2 rounded "
          >
            Add Documents
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

const Confirmation = ({
  synopsisChanged,
  totalActiveDocs,
  caseId,
  caseSynopsis,
  closeModal,
}: {
  synopsisChanged: boolean;
  totalActiveDocs: number;
  caseId: string | null;
  caseSynopsis: string;
  closeModal: () => void;
}) => {
  const { mutate } = useUpdateCase();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');

  const handleUpdateCase = () => {
    setIsLoading(true);
    mutate(
      {
        caseId: caseId,
        updateSynopsisActive: synopsisChanged,
        synopsis: caseSynopsis,
      },
      {
        onSuccess: () => {
          setResult('success');
          setIsLoading(false);
          setTimeout(() => {
            closeModal();
          }, 2000);
        },
        onError: () => {
          setResult('failed');
          setIsLoading(false);
          setTimeout(() => {
            closeModal();
          }, 2000);
        },
      },
    );
  };

  return (
    <div className="px-8 py-4 text-sm text-gray-800 ">
      {isLoading ? (
        <div className="flex flex-col justify-center items-center h-52">
          <StageSpinner size={30} color={'#4161FF'} />
        </div>
      ) : result === 'failed' ? (
        <div className="flex flex-col gap-4 pt-10 items-center h-52">
          <p className="text-xl font-semibold flex items-center gap-2">
            <FontAwesomeIcon icon={faWarning} className="h-5 w-5 text-red-700" />
            Update Failed
          </p>
          <p className="text-sm text-gray-500">
            Please{' '}
            <a href="mailto:support@wexler.ai" className="text-blue-600">
              contact
            </a>{' '}
            the Wexler team.
          </p>
        </div>
      ) : result === 'success' ? (
        <div className="flex flex-col gap-4 pt-10 items-center h-52">
          <p className="text-xl font-semibold flex items-center gap-2">
            <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 text-green-700" />
            Update In Progress
          </p>
          <p className="text-sm text-gray-500">Please check back later, you can go back to your case now.</p>
        </div>
      ) : (
        <>
          <p className="text-gray-500 text-xs">
            Make sure you have made all of the necessary changes to your case details. Updating a case can be time
            consuming.
          </p>
          <p className="text-xs text-gray-500 mt-2">
            If there are unwanted documents in this update please remove them from the Documents tab.
          </p>

          <div className="mt-4 pt-4 border border-x-0 border-b-0">
            <p className="">
              This update will include: {synopsisChanged && <b className="underline">synopsis</b>}
              {synopsisChanged && totalActiveDocs > 0 && ', '}
              {totalActiveDocs > 0 && (
                <b className="underline">
                  {totalActiveDocs} {totalActiveDocs === 1 ? 'document' : 'documents'}
                </b>
              )}
            </p>

            <div className="flex gap-10 mt-6 mb-2">
              <button
                onClick={handleUpdateCase}
                className="text-base border py-2 px-4 rounded text-white bg-brandSecondary hover:bg-brandSecondary-hover"
              >
                Update
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Documents = ({
  openModal,
  setTotalActiveDocs,
  synopsisChanged,
}: {
  openModal: (open: boolean) => void;
  setTotalActiveDocs: (docNumber: number) => void;
  synopsisChanged: boolean;
}) => {
  const [uploadDisabled, setUploadDisabled] = useState(false);
  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-xl mb-2">Documents</p>
        <p className="text-gray-700 mb-1 text-sm">Upload additional documents.</p>
        <FileUploaderComponent setUploadDisabled={setUploadDisabled} setTotalActiveDocs={setTotalActiveDocs} />
      </div>
      <div className="flex justify-end">
        <button
          disabled={uploadDisabled && !synopsisChanged}
          onClick={() => openModal(true)}
          className="disabled:cursor-not-allowed disabled:bg-gray-300 flex gap-2 items-center justify-center py-1 px-2 rounded bg-buttonPrimary shadow text-white hover:bg-buttonPrimary-hover"
        >
          <FontAwesomeIcon icon={faRefresh} />
          Update Case
        </button>
      </div>
    </div>
  );
};

const CaseDetails = ({ setActiveTab }: { setActiveTab: (tab: string) => void }) => {
  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-xl mb-4">Case Details</p>
        <p>Update your case synopsis, add more documents or both.</p>

        <div className="text-sm mt-6 flex flex-col gap-6">
          <div
            onClick={() => setActiveTab('Synopsis')}
            className="border border-blue-200 py-2 px-3 rounded-md hover:bg-gray-50 hover:border-blue-300 hover:cursor-pointer transition-all duration-300"
          >
            <p className="font-semibold mb-1">
              1. Synopsis
              <FontAwesomeIcon icon={faBook} className="text-gray-700 ml-2" />
            </p>
            <div className="flex flex-col gap-2 text-gray-700">
              <p>
                Updating your case synopsis will amend the 'significance' of each fact, which explains how a fact
                relates to the case's core legal issues.
              </p>
              <p>It will also generate a new summary of the case, and may alter the key themes and key facts.</p>
            </div>
          </div>

          <div
            onClick={() => setActiveTab('Documents')}
            className="border border-blue-200 py-2 px-3 rounded-md hover:bg-gray-50 hover:border-blue-300 hover:cursor-pointer transition-all duration-300"
          >
            <p className="font-semibold mb-1">
              2. Add Documents
              <FontAwesomeIcon icon={faFile} className="text-gray-700 ml-2" />
            </p>
            <div className="flex flex-col gap-2 text-gray-700">
              <p>Adding new documents to your case will extract new facts from these documents.</p>
              <p>It will also generate a new summary of the case, and may alter the key themes and key facts.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          onClick={() => setActiveTab('Synopsis')}
          className="bg-buttonPrimary shadow text-white hover:bg-buttonPrimary-hover flex gap-2 items-center justify-center py-1 px-2 rounded"
        >
          Update Synopsis
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

const NavItem = ({
  title,
  icon,
  onClick,
  active = false,
  className,
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  active?: boolean;
  className?: string;
}) => {
  return (
    <p
      className={
        `hover:bg-gray-100 hover:cursor-pointer transition-all duration-300 w-full py-1 flex items-center gap-2 ${
          active ? 'bg-gray-100' : ''
        } ` + className
      }
      onClick={onClick}
    >
      {icon}
      {title}
    </p>
  );
};
const SettingsModal = ({ caseId, handleClose }: { caseId: string; handleClose: () => void }) => {
  const TABS = { CASE_DETAILS: 'Case Details', SYNOPSIS: 'Synopsis', DOCUMENTS: 'Documents', SETTINGS: 'Settings' };
  const [activeTab, setActiveTab] = useState(TABS.CASE_DETAILS);
  const [confimationModalOpen, setConfirmationModalOpen] = useState(false);
  const [totalActiveDocs, setTotalActiveDocs] = useState(0);

  // -------- Synopsis state at this level so that it will persist between tabs
  const [caseName, setCaseName] = useState('');
  const [originalSynopsis, setOriginalSynopsis] = useState('');
  const [synopsisChanged, setSynopsisChanged] = useState(false);
  const [caseSynopsis, setCaseSynopsis] = useState('');
  const { isFetching, data: caseObjectResp } = useFetchCaseObject(caseId);

  useEffect(() => {
    if (caseObjectResp?.caseObject) {
      setCaseName(caseObjectResp?.caseObject?.case_name);
      setCaseSynopsis(caseObjectResp?.caseObject?.case_legal_issues);
      setOriginalSynopsis(caseObjectResp?.caseObject?.case_legal_issues);
    }
  }, [caseObjectResp]);

  const handleSynopsisChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseSynopsis(event.target.value);
  };

  useEffect(() => {
    setSynopsisChanged(caseSynopsis.trim() !== originalSynopsis.trim());
  }, [caseSynopsis, originalSynopsis]);
  // --------------------------------------------------------------------------

  const renderContent = (
    tab: string,
    setConfirmationModalOpen: (open: boolean) => void,
    setTotalActiveDocs: (docNumber: number) => void,
  ) => {
    switch (tab) {
      case 'Case Details':
        return <CaseDetails setActiveTab={setActiveTab} />;
      case 'Synopsis':
        return (
          <Synopsis
            caseName={caseName}
            caseSynopsis={caseSynopsis}
            isFetching={isFetching}
            handleSynopsisChange={handleSynopsisChange}
            setActiveTab={setActiveTab}
          />
        );
      case 'Documents':
        return (
          <Documents
            openModal={setConfirmationModalOpen}
            setTotalActiveDocs={setTotalActiveDocs}
            synopsisChanged={synopsisChanged}
          />
        );
    }
  };

  return (
    <div className="flex h-[500px]">
      <div className="pt-6 pb-10 w-48 border border-y-0 border-l-0 flex flex-col justify-between">
        <div className="flex flex-col">
          <NavItem
            title="Case Details"
            icon={<FontAwesomeIcon icon={faCircleInfo} className="w-4 text-brandSecondary" />}
            active={activeTab === 'Case Details'}
            onClick={() => setActiveTab('Case Details')}
            className="pl-6"
          />
          <div className="">
            <NavItem
              title="Synopsis"
              icon={<FontAwesomeIcon icon={fa1} className="w-2 h-2 rounded-full bg-brandSecondary p-1 text-white" />}
              active={activeTab === 'Synopsis'}
              onClick={() => setActiveTab('Synopsis')}
              className="pl-9 text-sm font-light"
            />
            <NavItem
              title="Documents"
              icon={<FontAwesomeIcon icon={fa2} className="w-2 h-2 rounded-full bg-brandSecondary p-1 text-white" />}
              active={activeTab === 'Documents'}
              onClick={() => setActiveTab('Documents')}
              className="pl-9 text-sm font-light"
            />
          </div>
        </div>
      </div>
      <div className="pt-6 pb-10 pl-6 pr-16 w-full">
        {renderContent(activeTab, setConfirmationModalOpen, setTotalActiveDocs)}
      </div>
      <Modal
        content={
          <Confirmation
            synopsisChanged={synopsisChanged}
            totalActiveDocs={totalActiveDocs}
            caseId={caseId}
            caseSynopsis={caseSynopsis}
            closeModal={() => {
              setConfirmationModalOpen(false);
              handleClose();
            }}
          />
        }
        title={
          <div className="text-base font-semibold h-full flex items-center gap-2 mt-1">
            <FontAwesomeIcon icon={faUpload} className="text-gray-700" />
            Update Case
          </div>
        }
        isOpen={confimationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        size="small"
      />
    </div>
  );
};

export default SettingsModal;
