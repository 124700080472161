import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
interface ViewCellProps {
  doc_id: string;
}
const ViewCell = ({ doc_id }: ViewCellProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewDoc = (doc_id: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', doc_id);
    searchParams.set('page', '0');
    navigate(location.pathname + '?' + searchParams.toString());
  };

  return (
    <div
      className=" text-white cursor-pointer flex justify-center items-center px-4 py-2 rounded bg-buttonPrimary hover:bg-buttonPrimary-hover m-auto mr-5"
      onClick={() => handleViewDoc(doc_id)}
    >
      View
    </div>
  );
};

export default ViewCell;
