import React, { useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';

interface ViewCellProps {
  document_author: string;
  doc_id: string;
}

const FileAuthorCell = ({ document_author, doc_id }: ViewCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFileAuthor, setCurrentFileAuthor] = useState(document_author);
  const { getFetchConfig } = useGetFetchConfig();

  const { isFetching: isLoadingUpdateFileAuthor, refetch: refetchUpdateFileAuthor } = useQuery(
    ['updateFileAuthor', doc_id],
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { fileAuthor: currentFileAuthor } });

      return fetch(`${APIBaseChronos}/client/case/doc/${doc_id}`, fetchConfig)
        .then((res) => {
          setEditMode(false);
          return res.json();
        })
        .catch((err) => {
          console.error('Failed to update file author:', err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const toggleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
      refetchUpdateFileAuthor();
    }
  };

  const handleChangeFileAuthor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFileAuthor(e.target.value);
  };

  return (
    <div className="flex flex-col justify-center items-start  not-italic py-5" key={doc_id}>
      <div className="flex flex-row items-center w-full justify-start">
        {editMode === true ? (
          <input
            onChange={handleChangeFileAuthor}
            value={currentFileAuthor}
            className="w-full"
            style={{ outline: 'none', border: '1px solid rgba(0,0,0,0.1)' }}
          />
        ) : isLoadingUpdateFileAuthor ? (
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        ) : (
          <div className="w-full text-left">{currentFileAuthor || '-'}</div>
        )}
        <FontAwesomeIcon
          onClick={toggleEditMode}
          icon={editMode ? faCheck : faPencil}
          className="text-gray-700 mr-2 ml-2 cursor-pointer"
          role="button"
          aria-label={editMode ? 'Save changes' : 'Edit file author'}
        />
      </div>
    </div>
  );
};

export default FileAuthorCell;
