import Select from 'react-select';

import { FILTER_OPTIONS } from '../../filterOptions';

interface FilterPopupProps {
  selectedOptions: OptionType[];
  setSelectedOptions: (v: OptionType[]) => void;
  filterOption: 'important' | 'classification' | 'agreed' | 'verified' | 'is_processed';
  className?: string;
}

interface OptionType {
  value: string | boolean;
  label: string;
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  selectedOptions,
  setSelectedOptions,
  filterOption,
  className = '',
}) => {
  const options = FILTER_OPTIONS[filterOption] || [];

  const onChange = (selected: any) => {
    setSelectedOptions(selected as OptionType[]);
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={onChange}
      options={options}
      placeholder="Select..."
      className={className}
    />
  );
};

export default FilterPopup;
