import { Navigate, Route, Routes } from 'react-router-dom';

import CaseCreator from './CaseCreator';
import CaseEditor from './CaseEditor';
import CasesExplorer from './CasesExplorer';
import CaseSynopsis from './CaseSynopsis';
import Guide from './Guide';
import MatterCreator from './MatterCreator';
import MatterEditor from './MatterCreator/MatterEditor';
import MattersExplorer from './MattersExplorer';
import UsageDashboard from './UsageDashboard';

const Chronos = () => {
  return (
    <Routes>
      <Route path="matters" element={<MattersExplorer />} />
      <Route path="usage-dashboard" element={<UsageDashboard />} />
      <Route path="explore" element={<CasesExplorer />} />
      <Route path="case-creator/:caseId" element={<CaseCreator />} />
      <Route path="matter-creator" element={<MatterCreator />} />
      <Route path="matter-editor/:matterId" element={<MatterEditor />} />
      <Route path="case-editor/*" element={<CaseEditor />} />
      <Route path="guide" element={<Guide />} />
      <Route path="case-synopsis" element={<CaseSynopsis />} />
      <Route path="*" element={<Navigate to="/app/chronos/matters" />} />
    </Routes>
  );
};

export default Chronos;
