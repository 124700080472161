import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

interface TextInputCellProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  updateState?: (val: string) => void;
  editable?: boolean;
  className?: string;
  setIsDateDirty?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

const TextInput = ({
  entryId,
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  updateState,
  editable,
  className,
  setIsDateDirty,
  callback,
}: TextInputCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFieldValue, setCurrentFieldValue] = useState(entryFieldValue || '');
  const { getFetchConfig } = useGetFetchConfig();

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '38px'; // Reset the height to its initial value
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const { isFetching: isLoadingUpdateFieldValue, refetch: refetchUpdateFieldValue } = useQuery(
    ['updateFieldValue', entryId, entryFieldKey],
    () => {
      setEditMode(false);
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: currentFieldValue } });
      return fetch(endpointToUpdate, fetchConfig)
        .then((res) => {
          if (entryFieldKey === 'date_of_subject_text' && setIsDateDirty) {
            setIsDateDirty(true);
          }
          if (updateState) updateState(currentFieldValue);
          callback && callback();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const togleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
      setTimeout(() => {
        autoResize(textAreaRef);
      }, 0);
    } else {
      refetchUpdateFieldValue();
    }
  };

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentFieldValue(e.target.value);
    autoResize(textAreaRef);
  };

  return (
    <div className={`flex flex-row items-center justify-start w-full ${className}`}>
      {editMode === true ? (
        <>
          <textarea
            ref={textAreaRef}
            className={`rounded-md pl-2 resize-none overflow-hidden w-full border`}
            onChange={handleChangeFieldValue}
            onClick={(e) => e.stopPropagation()}
            value={currentFieldValue}
          />
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              togleEditMode();
            }}
            icon={faCheck}
            className="text-gray-700 ml-2 cursor-pointer"
          />
        </>
      ) : isLoadingUpdateFieldValue ? (
        <div className="w-full flex justify-center items-center">
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        </div>
      ) : (
        <>
          <div>
            {currentFieldValue}

            {editable && (
              <span>
                <FontAwesomeIcon
                  icon={faPencil}
                  className="ml-2 mb-[2px] w-3 h-3 transition-all duration-500 hover:scale-125 hover:cursor-pointer hover:text-gray-800"
                  onClick={(e) => {
                    e.stopPropagation();
                    togleEditMode();
                  }}
                />{' '}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TextInput;
