import { MutableRefObject, useCallback } from 'react';

import { faArrowLeft, faDownload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as ExcelIcon } from 'assets/Excel.svg';
import Button from 'components/atoms/Button';

import Pagination, { PaginationProps } from '../../../Pagination';
import DataViewToolbar from '../../components/DataViewToolbar';
// import SearchBox from '../../components/SearchBox';

interface ChronologyTableToolbarProps {
  globalFilter: string;
  chronologyTitle: string;
  isLoadingDownload: boolean;
  viewDownloadOptions: boolean;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  setGlobalFilter: (value: string) => void;
  toggleViewDownloadOptions: () => void;
  fetchDownload: (docType: string) => void;
  handleBack: () => void;
  openModal: () => void;
  goToPage: (value: number) => void;
  onSearchCall: () => void;
}

const ChronologyTableToolbar = ({
  globalFilter,
  setGlobalFilter,
  chronologyTitle,
  wrapperRef,
  viewDownloadOptions,
  fetchDownload,
  toggleViewDownloadOptions,
  handleBack,
  openModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  goToPage,
  onSearchCall,
}: ChronologyTableToolbarProps & PaginationProps) => {
  const handleDownloadClick = useCallback(() => {
    fetchDownload('xlsx');
  }, [fetchDownload]);

  return (
    <DataViewToolbar
      titleSection={
        <div className="flex items-center pr-4">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="ml-2 mr-3 cursor-pointer text-gray-700 border rounded-full p-1 bg-gray-50 h-3 w-3"
            onClick={handleBack}
          />
          <div className="text-blue-500 font-semibold">{chronologyTitle}</div>
        </div>
      }
      longTitleSection={true}
      actionButtons={
        <>
          <Button
            icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
            className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
            onClick={openModal}
            text="Add Fact"
          />

          <div className="relative" ref={wrapperRef}>
            {/* Disable if isLoadingDownload */}
            <Button
              icon={<FontAwesomeIcon icon={faDownload} className="pr-2" />}
              className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
              onClick={toggleViewDownloadOptions}
              text="Download"
            />

            {viewDownloadOptions && (
              <div
                className="absolute left-11 top-8 bg-white w-72 rounded-2xl"
                style={{ zIndex: 1000, boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <div className="flex flex-col w-full cursor-pointer">
                  <div
                    className="w-full p-4 hover:bg-slate-100  rounded-t-2xl flex flex-row items-center"
                    onClick={handleDownloadClick}
                  >
                    <ExcelIcon className="mr-4" /> Download Excel
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
      searchBar={
        // TODO: Add back search bar, temporarily removed
        // <SearchBox
        //   value={globalFilter}
        //   placeholder={'Search chronology'}
        //   onChange={setGlobalFilter}
        //   onSearchCall={onSearchCall}
        // />
        <></>
      }
      pagination={
        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          goToPage={goToPage}
        />
      }
    />
  );
};

export default ChronologyTableToolbar;
