import React from 'react';

import { PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import { CircleSpinner } from 'react-spinners-kit';

interface StatusDisplayProps {
  casePipelineStatus: string;
  message: string;
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({ casePipelineStatus, message }) => {
  if (casePipelineStatus === PIPELINE_STATUS.failed.value) {
    return (
      <div className="text-black text-3xl not-italic font-semibold mb-8 flex flex-row items-center mt-12">
        <div className="mr-4">
          Chronos pipeline error: a member of the Wexler technical staff has been informed & will reach out shortly when
          the issue has been resolved
        </div>
        ❌
      </div>
    );
  }

  return (
    <div>
      <div
        className="text-black text-3xl not-italic font-semibold mb-6 flex flex-row items-center"
        style={{ color: '#6D6C6C' }}
      >
        {message}
      </div>
      <div className='"text-black text-2xl italic font-semibold mb-5 mt-2' style={{ color: '#8b0000' }}>
        Please do not close this tab
      </div>
      {PIPELINE_PROCESSING_STATUS.map((status: string, index) => {
        const done = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) > index;
        const inProgress = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) === index;
        const todo = PIPELINE_PROCESSING_STATUS.indexOf(casePipelineStatus) < index;
        return (
          <div
            key={status}
            className={`text-black text-3xl not-italic font-semibold mb-8 flex flex-row items-center  ${
              todo ? 'opacity-20' : ''
            }`}
          >
            <div className="mr-4">{PIPELINE_STATUS[status]?.label}</div>
            {inProgress && <CircleSpinner color={'#000000'} size={24} />}
            {done && '✅'}
          </div>
        );
      })}
    </div>
  );
};

export default StatusDisplay;
