import React from 'react';

import DatePickerInput from 'components/atoms/DatePickerInput';
import MultiSelect from 'components/atoms/MultiSelect';
import { ActionMeta, MultiValue } from 'react-select';
import { MyOptionType } from 'types';

interface FiltersProps {
  startDate: Date | null;
  endDate: Date | null;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  userOptions: MyOptionType[];
  selectedUsers: MultiValue<MyOptionType>;
  onChangeSelectedUsers: (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void;
  matterOptions: MyOptionType[];
  selectedMatters: MultiValue<MyOptionType>;
  onChangeSelectedMatters: (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void;
  organisationOptions?: MyOptionType[];
  selectedOrganisations?: MultiValue<MyOptionType>;
  onChangeSelectedOrganisations?: (newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void;
}

const Filters: React.FC<FiltersProps> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  userOptions,
  selectedUsers,
  onChangeSelectedUsers,
  matterOptions,
  selectedMatters,
  onChangeSelectedMatters,
  organisationOptions,
  selectedOrganisations,
  onChangeSelectedOrganisations,
}) => {
  return (
    <div className="flex flex-row items-start justify-center gap-4">
      <DatePickerInput selectedDate={startDate} onChange={onChangeStartDate} placeholderText="Start Date" />
      <DatePickerInput selectedDate={endDate} onChange={onChangeEndDate} placeholderText="End Date" />
      <MultiSelect
        className="w-52 text-sm"
        options={userOptions}
        selectedOptions={selectedUsers}
        onChange={onChangeSelectedUsers}
        placeholder="Select users"
      />
      <MultiSelect
        className="w-52 text-sm"
        options={matterOptions}
        selectedOptions={selectedMatters}
        onChange={onChangeSelectedMatters}
        placeholder="Select matters"
      />
      {organisationOptions && selectedOrganisations && onChangeSelectedOrganisations && (
        <MultiSelect
          className="w-52 text-sm"
          options={organisationOptions}
          selectedOptions={selectedOrganisations}
          onChange={onChangeSelectedOrganisations}
          placeholder="Select organisation"
        />
      )}
    </div>
  );
};

export default Filters;
