const TOUR_STYLES = {
  backgroundColor: 'rgb(249 250 251)',
  borderRadius: '12px',
  color: 'rgb(55 65 81)',
};

export const THEME_STEPS = [
  {
    selector: '#themes',
    content: 'Wexler extracts key themes across your documents from your case synopsis and the fully body of facts.',
    style: TOUR_STYLES,
  },
  {
    selector: '#theme-0',
    content: 'Select a theme to view its content.',
    action: (node: any) => {
      // Check if it is already open
      if (node.getAttribute('data-open') !== 'true') {
        node.click();
      }
    },
    style: TOUR_STYLES,
  },

  {
    selector: '#theme-0-text',
    content: 'Read a summary of the theme and its relevance to the case.',
    style: TOUR_STYLES,
  },
  {
    selector: '#theme-0-subquestion-0',
    content: 'Explore further questions on that theme.',
    style: TOUR_STYLES,
  },
];

export const CHRONOLOGY_STEPS = [
  {
    selector: '#include-selector-tour',
    content: 'Select facts to include them in your chronology.',
    style: TOUR_STYLES,
  },
  {
    selector: '#chronology-dropdown',
    content: "When you've selected your facts, select chronology.",
    action: (node: any) => {
      // Check if it is already open
      if (node.getAttribute('data-open') !== 'true') {
        node.click();
      }
    },
    style: TOUR_STYLES,
  },
  {
    selector: '#chronology-dropdown-options',
    content: "Either create a new chronology or add your facts to a chronology you've already made.",
    style: TOUR_STYLES,
  },
];

export const SEARCH_STEPS = [
  {
    selector: '#facts-search-bar',
    content: "Use Wexler's semantic search feature to interrogate your facts.",
    style: TOUR_STYLES,
  },
];
