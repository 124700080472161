import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export const mockUserOptions = {
  users: [
    {
      email: 'hameed@gmail.com',
      userId: '2',
    },
    {
      email: 'kush@gmail.com',
      userId: '3',
    },
    {
      email: 'luis@gmail.com',
      userId: '4',
    },
    {
      email: 'thomas@gmail.com',
      userId: '5',
    },
    {
      email: 'luis@gmail.com',
      userId: '4',
    },
    {
      email: 'thomas@gmail.com',
      userId: '5',
    },
    {
      email: 'luis@gmail.com',
      userId: '4',
    },
    {
      email: 'thomas@gmail.com',
      userId: '5',
    },
    {
      email: 'luis@gmail.com',
      userId: '4',
    },
    {
      email: 'thomas@gmail.com',
      userId: '5',
    },
    {
      email: 'luis@gmail.com',
      userId: '4',
    },
    {
      email: 'thomas@gmail.com',
      userId: '5',
    },
  ],
};

const mock = false;

const useOrgUsers = () => {
  const { fetchConfigGET } = useGetFetchConfig();
  const fetchOrgUsers = () => {
    if (mock) {
      return Promise.resolve(mockUserOptions);
    } else {
      return fetch(`${APIBaseChronos}/client/org/users`, fetchConfigGET)
        .then((res) => res.json())
        .catch((error) => console.error('Error fetching organization users:', error));
    }
  };

  return useQuery(['orgUsers'], fetchOrgUsers, {
    enabled: false,
    cacheTime: 0,
  });
};

export default useOrgUsers;
