import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';

interface ViewCellProps {
  is_processed: boolean;
  processing_status: boolean;
  content_filter: boolean;
  doc_id: string;
}

const DocumentIsProcessedCell = ({ is_processed, content_filter, doc_id, processing_status }: ViewCellProps) => {
  return (
    <div className="cursor-pointer flex flex-row justify-center items-center px-2 py-3 rounded-lg m-auto not-italic font-bold  w-24 h-10 relative">
      {!processing_status && !is_processed && content_filter && (
        <div className="absolute right-2 top-3.5 ">
          <ReactInfo
            data-tooltip-id={`processed-tooltip-cell-${doc_id}`}
            data-tooltip-content="Document did not pass content filter, possible illicit content forbidden by model provider. Please manually add facts"
            className="text-gray-700 mr-2 text-sm cursor-pointer"
            style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
          />
          <Tooltip id={`processed-tooltip-cell-${doc_id}`} style={TooltipStyles} />
        </div>
      )}
      {processing_status && '⏳'}
      {!processing_status && !is_processed && !content_filter && '❌'}
      {!processing_status && !is_processed && content_filter && '⛔'}
      {!processing_status && is_processed && '✅'}
    </div>
  );
};

export default DocumentIsProcessedCell;
