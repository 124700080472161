import Button from 'components/atoms/Button';

import { UserObject } from '../types/user';

interface UserListInterface {
  users: UserObject[];
  onRemove: (userId: string) => void;
}

const UserList = ({ users, onRemove }: UserListInterface) => {
  return (
    <div className="w-full flex flex-col gap-4 mt-6 text-sm h-48 overflow-y-scroll p-2 bg-gray-100 border rounded-lg">
      {users?.map((user: any) => {
        return (
          <div className="bg-white py-1 px-2 rounded w-full h-fit" key={user.user_id}>
            <div className=" flex items-center justify-between">
              <div className={`not-italic font-medium w-full text-black`}>{user.user_email}</div>
              <Button onClick={() => onRemove(user.user_id)} text="Remove" rounded="md" type="primary" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserList;
