import React from 'react';

import { faArrowRight, faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchBoxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onSearchCall: () => void;
  loading?: boolean;
  clearSearch?: () => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange, placeholder, onSearchCall, clearSearch }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchCall();
    }
  };
  return (
    <div className="pl-2 relative bg-white flex flex-row items-center justify-center rounded-md border border-gray-300 text-sm xl:w-72">
      <FontAwesomeIcon icon={faSearch} className="  text-gray-400" />
      <input
        className="rounded-md h-8 pl-4 w-full focus:outline-none not-italic font-normal text-black placeholder:text-gray-400 bg-white"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || 'Search cases'}
        onKeyDown={onKeyDown}
      />
      {clearSearch && (
        <button
          className={`text-gray-600 cursor-pointer transition-opacity duration-500 mr-2 ${
            value ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={clearSearch}
        >
          <FontAwesomeIcon icon={faX} className="h-3 w-3" />
        </button>
      )}
      <button
        className="cursor-pointer border-l border-gray-300 rounded-r flex justify-center items-center p-2 px-3 h-8  bg-brandSecondary hover:bg-brandSecondary-hover disabled:bg-gray-500 font-semibold text-white "
        onClick={onSearchCall}
      >
        <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3" />
      </button>
    </div>
  );
};

export default React.memo(SearchBox);
