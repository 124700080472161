import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetThreads = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getThreads = async () => {
    const response = await fetch(`${APIBaseChronos}/client/case/assistant/threads/${caseId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching threads failed');
    }
    return response.json();
  };

  const {
    isFetching: isLoadingThreads,
    data: responseThreads,
    refetch: refetchThreads,
    error,
  } = useQuery(['threadsResult', caseId], getThreads, {
    cacheTime: 0,
    enabled: false,
  });

  return {
    isLoadingThreads,
    responseThreads,
    refetchThreads,
    error,
  };
};

export default useGetThreads;
