import { KEY_VALUE_TYPE } from 'types';

export const DISPUTE_STATUS_OPTIONS: KEY_VALUE_TYPE = {
  PreAction: {
    value: 'Pre-action',
    label: 'Pre-action',
  },
  Claim: {
    value: 'Claim',
    label: 'Claim',
  },
  Trial: {
    value: 'Trial',
    label: 'Trial',
  },
  PostTrial: {
    value: 'Post-trial',
    label: 'Post-trial',
  },
};
