import { useCallback, useEffect, useState } from 'react';

import {
  ColumnFiltersState,
  OnChangeFn,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosDoc, FilterOption } from 'types';

import DocumentEditorToolbar from './DocumentEditorToolbar';
import { filterFns } from './filterFns';
import { HeaderContent } from './HeaderContent';
import useFetchDocs from './hooks/useFetchDocs';
import useGetDocumentColumns from './useGetDocumentColumns';
import { useTablePagination } from '../hooks/useTablePagination';

const initialSortingState = [
  {
    id: 'file_name',
    desc: false,
  },
];

const DocumentEditor = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const [globalFilter, setGlobalFilter] = useState('');
  const [appliedGlobalFilter, setAppliedGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);

  const location = useLocation();

  const { pagination, setPagination } = useTablePagination();

  const navigate = useNavigate();

  const columns = useGetDocumentColumns();

  const onPaginationChange = useCallback<OnChangeFn<PaginationState>>(
    (updaterOrValue) => {
      // @ts-ignore
      const newPaginationState: PaginationState = updaterOrValue(pagination);
      searchParams.set('page', String(newPaginationState.pageIndex + 1));
      navigate(location.pathname + '?' + searchParams.toString());
      setPagination(newPaginationState);
    },
    // eslint-disable-next-line
    [pagination, searchParams, navigate, location.pathname],
  );

  const table = useReactTable<ChronosDoc>({
    columns,
    data: docs,
    state: { globalFilter: appliedGlobalFilter, columnFilters, sorting, pagination },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: filterFns.fuzzyFilter,
    initialState: { pagination },
    onSortingChange: setSorting,
  });

  const { data: responseDocs, refetch: refetchCaseDocs, isLoading: isLoadingDocsTable } = useFetchDocs(caseId);

  const onSearchCall = () => {
    setAppliedGlobalFilter(globalFilter);
  };

  const clearSearch = async () => {
    setGlobalFilter('');
    setAppliedGlobalFilter('');
  };

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (responseDocs?.docs && JSON.stringify(docs) !== JSON.stringify(responseDocs.docs)) {
      setDocs(responseDocs.docs.filter((doc: ChronosDoc) => !doc.is_removed));
    }
    // eslint-disable-next-line
  }, [responseDocs]);

  const processedCount = docs.filter((doc) => doc.is_processed).length;
  const rowsToRender = table.getRowModel().rows;
  const isEmptyAfterFiltering = rowsToRender.length === 0;

  const headersLength = table.getHeaderGroups()[0].headers.length;
  const clearAllFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);

  return (
    <div className="flex flex-col h-full">
      <DocumentEditorToolbar
        processedCount={processedCount}
        totalDocs={docs.length}
        openSettingsModal={openSettingsModal}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        onSearchCall={onSearchCall}
        clearSearch={clearSearch}
        table={table}
      />
      <div className="overflow-y-scroll mb-6">
        {isLoadingDocsTable && (
          <div className="w-full h-24 flex items-center justify-center">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        )}
        <div className="w-full flex flex-column pb-6 mt-2 pr-2 rounded-xl border border-slate-200">
          {docs && docs.length > 0 && (
            <div className="w-full">
              <table className="w-full">
                <thead className="h-12 text-sm" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const filterValue = header.column.getFilterValue() as FilterOption[];

                        return (
                          <th
                            key={header.id}
                            className="not-italic font-medium"
                            style={{
                              width: header.column.getSize(),
                              color: 'var(--colors-primary-slate-400, #8897AE)',
                            }}
                          >
                            <HeaderContent header={header} filterValue={filterValue} />
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="text-xs">
                  {isEmptyAfterFiltering ? (
                    <tr>
                      <td colSpan={headersLength}>
                        <div className="flex justify-center items-center flex-col mt-10">
                          <div className="mb-5">No results</div>
                          <div
                            className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                            onClick={clearAllFilters}
                          >
                            Clear filters
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    rowsToRender.map((row, i) => {
                      return (
                        <tr
                          style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB', borderBottom: '1px solid #c8cad7' }}
                          key={row.id}
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td className="pl-3" key={cell.id} id={cell.id} style={{ width: cell.column.getSize() }}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentEditor;
