export const getAuthBase = () => {
  const environment = process.env.REACT_APP_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://auth.dev.app.wexler.ai';
    case 'production':
      return 'https://auth.app.wexler.ai';
    case 'test':
      return 'https://auth.dev.app.wexler.ai';
    case 'local':
      return 'https://64007810.propelauthtest.com';
    default:
      return 'https://64007810.propelauthtest.com';
  }
};

export const APIBase = getAuthBase();
