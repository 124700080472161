import React, { useEffect, useState } from 'react';

import { ChronosFact } from 'types';

import FactDropdown from './components/FactDropdown';

const KeyFactsTimeline = ({
  factProps,
  userId,
  references,
}: {
  factProps: ChronosFact[];
  userId: string;
  references: { [key: string]: number };
}) => {
  // State
  const [facts, setFacts] = useState<ChronosFact[]>([]);

  // Effects
  useEffect(() => {
    // Sort the factProps array by date_of_subject in ascending order
    const sortedFacts = [...factProps].sort((a, b) => {
      const dateA = a.date_of_subject ? new Date(a.date_of_subject).getTime() : Infinity;
      const dateB = b.date_of_subject ? new Date(b.date_of_subject).getTime() : Infinity;
      return dateA - dateB;
    });

    // Set the sorted facts to state (TODO: maybe better way of doing this across thousands of facts)
    setFacts(sortedFacts);
  }, [factProps]);

  return (
    <>
      {facts ? (
        <div className="relative flex flex-col gap-4">
          {/* Vertical line */}
          <div className="absolute left-4 top-0 h-full border-l-2 border-gray-300"></div>

          {facts.map((fact: ChronosFact, index: number) => (
            <div key={index} className="">
              <div className="flex items-center">
                {/* Marker */}
                <div className="w-5 h-5 rounded-full absolute left-[7px] bg-white border-2 border-brandSecondary"></div>
                {/* Fact content */}
                <div
                  key={fact.message}
                  id={fact.event_id}
                  className="ml-10 border-2 border-opacity-40 rounded bg-gray-50 w-full"
                >
                  <FactDropdown fact={fact} userId={userId} reference={references[fact.event_id]} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default KeyFactsTimeline;
