import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChronosFact } from 'types';

import FactDropdown from '../../CaseSummary/components/KeyFactsTimeline/components/FactDropdown';
import IncludeCheckbox from '../../components/IncludeCheckbox';

interface FactesEditorTableProps {
  facts: ChronosFact[];
  keyFactIdSet: Set<string>;
  userId: string;
  caseId: string;
  setIsDateDirty: (v: boolean) => void;
  setTotalNumberOfIncludedFacts: (v: number | ((prev: number) => number)) => void;
}

const FactsEditorTable = ({
  facts,
  keyFactIdSet,
  userId,
  caseId,
  setIsDateDirty,
  setTotalNumberOfIncludedFacts,
}: FactesEditorTableProps) => {
  return (
    <div className="flex flex-col gap-2 pb-6 pt-1 pr-2">
      {facts.map((fact) => (
        <div key={fact.event_id} className="flex gap-3 relative">
          <IncludeCheckbox
            included={fact.included}
            eventId={fact.event_id}
            caseId={caseId || ''}
            setTotalNumberOfIncludedFacts={setTotalNumberOfIncludedFacts}
          />
          <div
            className={`border w-full rounded-lg ${
              keyFactIdSet && keyFactIdSet.has(fact.event_id)
                ? 'border-yellow-500 border-opacity-50 gold-glow-card'
                : ''
            } ${fact.important === 'No' ? 'border-dashed border- opacity-80' : ''}`}
          >
            <FactDropdown fact={fact} userId={userId} compressedVersion={true} setIsDateDirty={setIsDateDirty} />
            {keyFactIdSet && keyFactIdSet.has(fact.event_id) ? (
              <FontAwesomeIcon icon={faStar} className="w-3 h-3 absolute top-2 right-5 text-yellow-500" />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FactsEditorTable;
