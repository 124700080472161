import Select from 'react-select';

interface OptionType {
  value: string | boolean;
  label: string;
}

interface ChronosDocumentCoordinate {
  doc_id: string;
  file_name: string;
}

interface FilterPopupProps {
  selectedOptions: OptionType[];
  setSelectedOptions: (v: OptionType[]) => void;
  allDocuments: ChronosDocumentCoordinate[];
  className?: string;
}

const FilterPopup = ({ selectedOptions, setSelectedOptions, allDocuments = [], className = '' }: FilterPopupProps) => {
  // Create a map of unique documents using reduce
  const uniqueDocumentsMap = allDocuments.reduce<Record<string, ChronosDocumentCoordinate>>((acc, doc) => {
    acc[doc.doc_id] = doc;
    return acc;
  }, {});

  const uniqueDocuments = Object.values(uniqueDocumentsMap);

  const options = uniqueDocuments.map((document) => ({
    value: document.doc_id,
    label: document.file_name.split('.').filter(Boolean)[0],
  }));

  return (
    <Select
      className={className}
      isMulti
      value={selectedOptions}
      onChange={(selected) => setSelectedOptions(selected as OptionType[])}
      options={options}
      placeholder="Select documents"
    />
  );
};

export default FilterPopup;
